import Vue from 'vue'
import {checkLogin,checkIdType} from './network'
//自定义指令根据后端返回值判断元素显示状态
const pulicEl = (el,binding,vnode)=> {
    let permissions = checkLogin("permissionsV2");//v2字段
    sessionStorage.setItem('demo',JSON.stringify(permissions))
    let permissionRole = checkLogin("permissionDeniedStyle");//permissionDeniedStyle权限
    if(permissions == undefined) return false;//接口不返回不进行下面判断
    let permissionsInfo = el.getAttribute("permissionInfo");//获取el自定义属性
    if(el.getAttribute("nopermission") == "true"){
        return;
    }
    // 自定义权限---#10387：财务设置合并后，多个不同权限的控制
    if(permissionsInfo === 'allChannel') {
        permissions['allChannel'] = [2,2];
    }
    let xy = [0, 0];
    if(permissionsInfo != undefined){
      permissionsInfo = permissionsInfo.replace(/\s*/g,"");  //去除空格
      let ps = permissionsInfo.split("&&");  //通过&&分割字符串
      let x=2;
      let y=2;
      //遍历每个权限, 例如 A:[2,1] + B:[1,2] = C[1,1]
      for(let p of ps){
        if(permissions[p] === undefined){
          permissions[p] = [0,0]
        }
        x = Math.min(x , permissions[p][0]);
        y = Math.min(y , permissions[p][1]);
      }
      xy = [x, y];
    }
    
    //如果元素为Element组件
    if(vnode.tag == "vue-component-26-ElButton"){
        //如果元素为Element按钮/text
        if(vnode.elm.className == "el-button el-button--text" || vnode.elm.className == "el-button el-button--text el-button--small" || vnode.elm.className== "el-button el-button--text is-disabled"){
            if(xy[0] == 0 && xy[1] == 0){
                if(permissionRole == 0) {
                    el.disabled = true;
                    el.style.color = "#c0ccda";
                }else if(permissionRole == 1){
                    el.style.display = "none"
                }
            }else if(xy[0] == 1 && xy[1] == 0){
                el.style.display = "none";
            }else if(xy[0] == 1 && xy[1] == 1){
                el.disabled = true;
                el.style.color = "#c0ccda";
            }else if(xy[0] == 2 && xy[1] == 0){
                el.style.display = "none";
            }else if(xy[0] == 2 && xy[1] == 1){
                el.disabled = true;
                el.style.color = "#c0ccda";
            }else if(xy[0] == 2 && xy[1] == 2){
                el.disabled = false;
            }
        }else{
            if(xy[0] == 0 && xy[1] == 0){
                if(permissionRole == 0) {
                    if (vnode.elm.className == "el-button _userClassInfo el-button--primary") {
                        el.style.display = "none";
                        return
                    }
                    el.disabled = true;
                    vnode.elm.className = "el-button el-button--default is-disabled add-time-btn"
                }else if(permissionRole == 1){
                    el.style.display = "none"
                }
            }else if(xy[0] == 1 && xy[1] == 0){
                el.style.display = "none";
            }else if(xy[0] == 1 && xy[1] == 1){
                el.disabled = true;
                vnode.elm.className = "el-button el-button--default is-disabled add-time-btn"
            }else if(xy[0] == 2 && xy[1] == 0){
                el.style.display = "none";
            }else if(xy[0] == 2 && xy[1] == 1){
                el.disabled = true;
                vnode.elm.className = "el-button el-button--default is-disabled add-time-btn"
            }else if(xy[0] == 2 && xy[1] == 2){
                el.disabled = false;
            }
        }
    }else{//标签元素类型
        if(vnode.elm.className.indexOf("menu_li") >= 0 && vnode.elm.className.indexOf("hidden") < 0){
            if (checkIdType("dealerLevel2")) {
                permissions['refundInfo'] = [2,0]
                permissions['wechatFeedbackInfo'] = [2,0]
                permissions['invoiceInfo'] = [2,0]
            }
            if(xy[0] == 0 && xy[1] == 0){
                if(el.getAttribute("ishide") == "true"){
                    el.style.display = "none";
                }else{
                    if(permissionRole == 0) {
                        vnode.elm.className = "menu_li is-disable"
                    }else if(permissionRole == 1){
                        el.style.display = "none"
                    }
                }
            }
            if(xy[0] == 2 && xy[1] == 0){
               el.style.display = "none"
            }
            if(xy[0] == 1 && xy[1] == 0){
               el.style.display = "none"
            }
        }else if(vnode.elm.className.indexOf("hidden") >= 0){
            if (checkIdType("dealerLevel2")) {
                permissions['refundInfo'] = [2,0]
                permissions['wechatFeedbackInfo'] = [2,0]
                permissions['invoiceInfo'] = [2,0]
            }
            if(xy[0] == 0 && xy[1] == 0){
                if(permissionRole == 0) {
                    el.style.display = "block"
                }else if(permissionRole == 1){
                    el.style.display = "none"
                }
            }
            if(xy[0] == 2 && xy[1] == 0){
               el.style.display = "none"
            }
            if(xy[0] == 1 && xy[1] == 0){
               el.style.display = "none"
            }
        }else if(vnode.tag == "div"){
            if(xy[0] == 0 && xy[1] == 0){
                if(permissionRole == 0) {
                    if(vnode.elm.className == "charging-info isEcharts"){
                       el.style.display = "none";
                       return
                    }
                    if (vnode.elm.className == "table userGradeInfo") {
                        el.style.display = "none";
                        return
                    }
                    if (vnode.elm.className == "_userClassInfo") {
                        el.style.display = "none";
                        return
                    }
                    if(vnode.elm.className == "coupon-info is-hide"){
                       el.style.display = "none";
                       return
                    }
                    el.style.display = "block"
                }else if(permissionRole == 1){
                    el.style.display = "none"
                }
            }else if(xy[0] == 1 && xy[1] == 0){
                el.style.display = "none";
            }else if(xy[0] == 2 && xy[1] == 0){
                el.style.display = "none";
            }
        }else{
            if(xy[0] == 0 && xy[1] == 0){
                el.style.display = "none";
            }else if(xy[0] == 1 && xy[1] == 0){
                el.style.display = "none";
            }else if(xy[0] == 2 && xy[1] == 0){
                el.style.display = "none";
            }
        }
    }
};

const setPermission = Vue.directive('setPermission',{
  inserted (el,binding,vnode) {
    pulicEl(el,binding,vnode)
  },
  update (el,binding,vnode) {
    pulicEl(el,binding,vnode)
  }
})
export {setPermission}
