var FUN_LIST = [
  // 待办事项
  {
    id: 1,
    label: '当前故障',
    permissions: "deviceMonitorList",
    type: ['event', 'fun'],
    url: 'deviceMonitor.html#maint',
  },
  {
    id: 2,
    label: '微信客服',
    permissions: "wechatFeedbackInfo",
    type: ['event', 'fun'],
    url: 'feedback.html',
  },
  {
    id: 3,
    label: '钱包退款受理',
    permissions: "refundInfo",
    type: ['event', 'fun'],
    url: 'walletRefundAccept.html',
  },
  {
    id: 4,
    label: '平台账单',
    permissions: "dealerAccountInfo",
    type: ['event', 'fun'],
    url: 'selfHelpPayCost.html#platformBillings',
  },
  {
    id: 5,
    label: '个人发票申请',
    permissions: "invoiceInfo",
    type: ['event', 'fun'],
    url: 'personalInvoiceApplication.html',
  },
  {
    id: 6,
    label: '团体发票申请',
    permissions: "userGroupInfo",
    type: ['event', 'fun'],
    url: 'groupInvoiceApplication.html',
  },
  {
    id: 7,
    label: '电源模块保养',
    permissions: "deviceMonitorList",
    type: ['event', 'fun'],
    url: 'powerMaint.html#power',
  },
  {
    id: 8,
    label: '设备寿命保养',
    permissions: "DeviceLifeStatsInfo",
    type: ['event', 'fun'],
    url: 'powerMaint.html#eq',
  },
  // 常用功能
  {
    id: 9,
    label: '现有场站',
    permissions: "siteInfo",
    type: ['fun'],
    url: 'managementDepot.html',
  },
  {
    id: 10,
    label: '资费模板',
    permissions: "siteInfo",
    type: ['fun'],
    url: 'rates.html',
  },
  {
    id: 11,
    label: '设备管理',
    permissions: "deviceMonitorList",
    type: ['fun'],
    url: 'deviceMonitor.html#eq',
  },
  {
    id: 12,
    label: '全部充电订单',
    permissions: "chargeBillInfo",
    type: ['fun'],
    url: 'bill.html',
  },
  {
    id: 13,
    label: '维保首页',
    permissions: "DeviceErrorLogInfo",
    type: ['fun'],
    url: 'maintenanceHome.html',
  },
  {
    id: 14,
    label: '实时监控',
    permissions: "deviceMonitorChart",
    type: ['fun'],
    url: 'chargeMonitor.html',
  },
  /* {
    id: 15,
    label: '异常充电订单',
    permissions: "chargeBillInfo",
    type: ['fun'],
    url: 'bill.html#abnormal',
  }, */
  {
    id: 16,
    label: '设备异常日志',
    permissions: "DeviceErrorLogInfo",
    type: ['fun'],
    url: 'errorLog.html',
  },
  {
    id: 17,
    label: '车辆充电日志',
    permissions: "VehicleChargeLogInfo",
    type: ['fun'],
    url: 'vehicleChargingLog.html',
  },
  {
    id: 18,
    label: '平台通信日志',
    permissions: "DeviceCommLogInfo",
    type: ['fun'],
    url: 'platformLog.html',
  },
  {
    id: 19,
    label: '工单管理',
    permissions: "workFlowInfo",
    type: ['fun'],
    url: 'orderManagement.html',
  },
  {
    id: 20,
    label: '用户管理',
    permissions: "wechatUserInfo",
    type: ['fun'],
    url: 'user.html',
  },
  {
    id: 21,
    label: '用户分类',
    permissions: "userClassInfo",
    type: ['fun'],
    url: 'userClassification.html',
  },
  /* {
    id: 22,
    label: '分类管理',
    permissions: "userClassInfo",
    type: ['fun'],
    url: 'userClassList.html',
  }, */
  {
    id: 23,
    label: '等级会员',
    permissions: "userGradeInfo",
    type: ['fun'],
    url: 'member.html',
  },
  {
    id: 24,
    label: '特权会员',
    permissions: "userGradeInfo",
    type: ['fun'],
    url: 'privilegedMember.html',
  },
  {
    id: 25,
    label: '团体管理',
    permissions: "userGroupInfo",
    type: ['fun'],
    url: 'group.html',
  },
  {
    id: 26,
    label: '卡组管理',
    permissions: "nfcgroupInfo",
    type: ['fun'],
    url: 'chargeCard.html',
  },
  {
    id: 27,
    label: '充电卡管理',
    permissions: "nfcgroupInfo",
    type: ['fun'],
    url: 'managementCharger.html#U3',
  },
  {
    id: 28,
    label: '抵用券',
    permissions: "couponTemplateInfo",
    type: ['fun'],
    url: 'coupon.html#dy',
  },
  {
    id: 29,
    label: '折扣券',
    permissions: "couponTemplateInfo",
    type: ['fun'],
    url: 'coupon.html#zk',
  },
  {
    id: 30,
    label: '代金券',
    permissions: "discountPackageInfo",
    type: ['fun'],
    url: 'cashCoupon.html#dj',
  },
  {
    id: 31,
    label: '电量包',
    permissions: "discountPackageInfo",
    type: ['fun'],
    url: 'cashCoupon.html#dl',
  },
  {
    id: 32,
    label: '活动总览',
    permissions: "promotionInfo",
    type: ['fun'],
    url: 'activityOverview.html',
  },
  {
    id: 33,
    label: '储值送返活动',
    permissions: "promotionInfo",
    type: ['fun'],
    url: 'storedValueActivity.html#value',
  },
  {
    id: 34,
    label: '自由领取活动',
    permissions: "promotionInfo",
    type: ['fun'],
    url: 'storedValueActivity.html#free',
  },
  // {
  //   id: 35,
  //   label: '车主组队打榜活动',
  //   permissions: "invoiceInfo",
  //   type: ['fun'],
  //   url: '',
  // },
  {
    id: 36,
    label: '财务概况',
    permissions: "invoiceInfo",
    type: ['fun'],
    url: 'financialHome.html',
  },
  {
    id: 37,
    label: '充值金额统计',
    permissions: "settleInfo",
    type: ['fun'],
    url: 'monthlyStatistics.html#recharge',
  },
  {
    id: 38,
    label: '退款金额统计',
    permissions: "refundInfo",
    type: ['fun'],
    url: 'monthlyStatistics.html#refund',
  },
  {
    id: 39,
    label: '用户钱包余额',
    permissions: "settleInfo",
    type: ['fun'],
    url: 'monthlyStatistics.html#wallet',
  },
  {
    id: 40,
    label: '站点利用率分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeXcharge.html#site',
  },
  {
    id: 41,
    label: '高峰时刻分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeXcharge.html#date',
  },
  {
    id: 42,
    label: '站点充电量分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeCharge.html#site',
  },
  {
    id: 43,
    label: '用户来源充电量分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeCharge.html#user',
  },
  {
    id: 44,
    label: '站点收入分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeIncome.html#site',
  },
  {
    id: 45,
    label: '用户来源收入分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeIncome.html#user',
  },
  {
    id: 46,
    label: '站点用户数分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeUser.html#site',
  },
  {
    id: 47,
    label: '用户来源订单数分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeUser.html#user',
  },
  {
    id: 48,
    label: '用户贡献分析',
    permissions: "biInfo",
    type: ['fun'],
    url: 'analyzeUser.html#contribution',
  },
  {
    id: 49,
    label: '卡券消耗分析',
    permissions: "couponTemplateInfo",
    type: ['fun'],
    url: 'cardAnalysis.html#card',
  },
]
module.exports = FUN_LIST;