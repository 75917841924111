export default {
    selfHelpPayCost_00001: '收费项目',
    selfHelpPayCost_00002: '充电桩',
    selfHelpPayCost_00003: '充电站',
    selfHelpPayCost_00004: '时长',
    selfHelpPayCost_00005: '收费金额（元）',
    selfHelpPayCost_00006: '元',
    selfHelpPayCost_00007: '年',
    selfHelpPayCost_00008: '月',
    selfHelpPayCost_00009: '账单详情',
    selfHelpPayCost_00010: '待出账单详情',
    selfHelpPayCost_00010: '待出账单详情',
    selfHelpPayCost_00011: '物联卡网费',
    selfHelpPayCost_00012: '账户详情',
    selfHelpPayCost_00013: '余额明细',
    selfHelpPayCost_00014: '充值',
    selfHelpPayCost_00015: '收费账单扣款',
    selfHelpPayCost_00016: '时间',
    selfHelpPayCost_00017: '内容',
    selfHelpPayCost_00018: '金额（元）',
    selfHelpPayCost_00019: '备注',
    selfHelpPayCost_00020: '微信支付（运营商）',
    selfHelpPayCost_00021: '支付宝支付（运营商）',
    selfHelpPayCost_00022: '北京智充电科技有限公司',
    selfHelpPayCost_00023: '账单',
    selfHelpPayCost_00024: '收费账单',
    selfHelpPayCost_00025: '每月1日10:00出上月收费账单，请确保每月10日19:30前账户余额能够足额退款，以免逾期欠费影响相关服务。',
    selfHelpPayCost_00026: '收费金额',
    selfHelpPayCost_00027: '已扣款',
    selfHelpPayCost_00028: '未扣款',
    selfHelpPayCost_00029: '已欠费',
    selfHelpPayCost_00030: '已开发票',
    selfHelpPayCost_00031: '未开发票',
    selfHelpPayCost_00032: '详情',
    selfHelpPayCost_00033: '充电桩分配到运营商#simFreePeriod#天后，#simPrice#/桩/30日。',
    selfHelpPayCost_00034: '账单日',
    selfHelpPayCost_00035: '当前金额',
    selfHelpPayCost_00035: '当前金额',
    selfHelpPayCost_00036: '收费周期',
    selfHelpPayCost_00037: '账户余额',
    selfHelpPayCost_00038: '充值金额',
    selfHelpPayCost_00039: '选择支付方式',
    selfHelpPayCost_00040: '微信支付',
    selfHelpPayCost_00041: '支付宝支付',
    selfHelpPayCost_00042: '其他金额',
    selfHelpPayCost_00043: '支付完成',
    selfHelpPayCost_00044: '上一步',
    selfHelpPayCost_00045: '账户充值',
    selfHelpPayCost_00046: '待出账单金额',
    selfHelpPayCost_00047: '欠费账单金额',
    selfHelpPayCost_00048: '已欠费',
    selfHelpPayCost_00049: '合计金额',
    selfHelpPayCost_00050: '请输入充值金额',
    selfHelpPayCost_00051: '选择支付方式',
    selfHelpPayCost_00052: '不能输入负数',
    selfHelpPayCost_00053: '充值成功',
    selfHelpPayCost_00054: '充值失败',
    selfHelpPayCost_00055: '请输入正确的充值金额',
    selfHelpPayCost_00056: '收费规则',
    selfHelpPayCost_00057: '物联卡网费 - 充电桩分配到运营商365天后，12.5元/桩/30日。',
    selfHelpPayCost_100058: '公对公转账',
    selfHelpPayCost_100059: '1、您可以使用您的企业网银公对公转账方式进行充值，平台会根据您转账交易流水中的付款人名称和转账金额自动匹配为您的平台账户充值。',
    selfHelpPayCost_100060: '2、请注意您的付款人名称需和您申请平台注册码时提供的公司名称（<span style="color:#FA545E">{title}</span>）一致，平台才能自动匹配账户充值成功。如您的公司名称有误，请联系平台修改。',
    selfHelpPayCost_100061: '3、公对公转账金额不会立即充值到您的平台账户中，会有几分钟延迟，最晚一般转账当天24时前充值成功。平台充值成功后会发送通知，也会给您注册手机号发送充值成功短信，请及时查看。可在余额明细中查看相关详情。',
    
    selfHelpPayCost_100062: '公对公转账收款人信息',
    selfHelpPayCost_100063: '收款人账号：110916224910502',
    selfHelpPayCost_100064: '收款人名称：北京智充科技有限公司',
    selfHelpPayCost_100065: '收款人开户行：招商银行股份有限公司北京大运村支行',
    selfHelpPayCost_100066: '收款人开户行联行号：308100005221',
    selfHelpPayCost_100067: '您有任何问题可拨打010-53609116 ，时间：工作日早10：00点至晚18：00点；您也可向您对接的智充业务同事进行咨询。',

    selfHelpPayCost_100068: '公对公转账（运营商）',
    selfHelpPayCost_100069: '流水号（收款行）:',
    selfHelpPayCost_100070: '交易日:',
    selfHelpPayCost_100071: '交易时间:',
    selfHelpPayCost_100072: '交易金额:',
    selfHelpPayCost_100073: '付款人账号:',
    selfHelpPayCost_100074: '付款人名称:',
    selfHelpPayCost_100075: '付款人开户行:',



    



    //平台账单
    selfHelpPayCost_10001: '订单编号',
    selfHelpPayCost_10002: '开始时间',
    selfHelpPayCost_10003: '结束时间',
    selfHelpPayCost_10004: '账单类型',
    selfHelpPayCost_10005: '平台使用费',
    selfHelpPayCost_10006: '物联卡网费',
    selfHelpPayCost_10007: '一次性收费',
    selfHelpPayCost_10008: '请关注平台为您发送的收费通知，保证平台正常使用，以免欠费后影响相关服务',
    selfHelpPayCost_10009: '平台账单',
    selfHelpPayCost_10010: '账单列表',
    selfHelpPayCost_10011: '批量开票',
    selfHelpPayCost_10012: '已支付',
    selfHelpPayCost_10013: '待支付',
    selfHelpPayCost_10014: '已欠费',
    selfHelpPayCost_10015: '未开发票',
    selfHelpPayCost_10016: '开票中',
    selfHelpPayCost_10017: '已开发票',
    selfHelpPayCost_10018: '去支付',
    selfHelpPayCost_10019: '去开票',
    selfHelpPayCost_10020: '查看开票进度',
    selfHelpPayCost_10021: '收费项目',
    selfHelpPayCost_10022: '收费版本',
    selfHelpPayCost_10023: '收费标准',
    selfHelpPayCost_10024: '收费时段',
    selfHelpPayCost_10025: '应收金额',
    selfHelpPayCost_10026: '实付金额',
    selfHelpPayCost_10027: '优惠金额',
    selfHelpPayCost_10028: '折算金额',
    selfHelpPayCost_10029: '支付时间',
    selfHelpPayCost_10030: '支付方式',
    selfHelpPayCost_10031: '开通方式',
    selfHelpPayCost_10032: '待处理',
    selfHelpPayCost_10033: '已开票',
    selfHelpPayCost_10034: '已拒绝',
    selfHelpPayCost_10035: '申请时间',
    selfHelpPayCost_10036: '开票金额',
    selfHelpPayCost_10037: '处理进度',
    selfHelpPayCost_10038: '定制版',
    selfHelpPayCost_10039: '高级版',
    selfHelpPayCost_10040: '基础版',
    selfHelpPayCost_10041: '微信支付',
    selfHelpPayCost_10042: '账户余额支付',
    selfHelpPayCost_10043: '自行开通',
    selfHelpPayCost_10044: '自动续费',
    selfHelpPayCost_10045: '业务码开通',
    selfHelpPayCost_10046: '{price}{currencyType}/卡/月，每月1日收取上月费用',
    selfHelpPayCost_10047: '{count}充电口，{dayDesc}({days}天)，{amount}{currencyType}',
    selfHelpPayCost_10048: '半年',
    selfHelpPayCost_10049: '一年',
    selfHelpPayCost_10050: '不限制充电口，{dayDesc}({days}天)，{amount}{currencyType}',
    selfHelpPayCost_10051: '按上月充电量，{amount}元/度，每月1日收取',
    selfHelpPayCost_10052: '按上月服务费，{amount}%比例，每月1日收取',
    selfHelpPayCost_10053: '上月服务费超过{amount}{currencyType}，超过部分按{price}%收取',
    selfHelpPayCost_10054: '微信支付',
    selfHelpPayCost_10055: '使用优惠码',
    selfHelpPayCost_10056: '请输入优惠码',
    selfHelpPayCost_10057: '更换优惠码',
    selfHelpPayCost_10058: '支付失败',
    selfHelpPayCost_10059: '关闭账单',
    selfHelpPayCost_10060: '确定关闭该账单？',
    selfHelpPayCost_10061: '待支付账单',
    selfHelpPayCost_10062: '欠费账单',
    selfHelpPayCost_10063: '账单支付超时，已无法支付，请关闭账单后重新购买',
    selfHelpPayCost_10064: '(优惠码{code}，',
    selfHelpPayCost_10065: '满{fee}元减{dis}元',
    selfHelpPayCost_10066: '(平台使用时间剩余{day}天)',
    selfHelpPayCost_10067: '待支付账单金额',
    selfHelpPayCost_10068: '查看',
    selfHelpPayCost_10069: '{count}个充电口',
    selfHelpPayCost_10070: '不限制充电口',
    selfHelpPayCost_10071: '收费说明',
    selfHelpPayCost_10072: '上月充电量超过{amount}度，超过部分按{price}{currencyType}/度收取',
    selfHelpPayCost_00058: '剩余{0}天',
    selfHelpPayCost_00059: '每月1日收取上月费用',
    selfHelpPayCost_00060: '已到期，平台已停止启动充电服务',
    selfHelpPayCost_00061: '已欠费，本月{day}平台将停止启动充电服务',
    selfHelpPayCost_00062: '已欠费，平台已停止启动充电服务',
    selfHelpPayCost_00063: '{simPrice}/桩/月',
    selfHelpPayCost_00064: '支付成功',
    selfHelpPayCost_00065: '物联卡数量',
    selfHelpPayCost_00066: '账单编号',
    selfHelpPayCost_00067: '下一步',

    // 开通服务
    selfHelpPayCost_20000: '当前充电口数量',
    selfHelpPayCost_20001: '直流',
    selfHelpPayCost_20002: '交流',
    selfHelpPayCost_20003: '版本功能对比',
    selfHelpPayCost_20004: '使用变更码',
    selfHelpPayCost_20005: '您已开通',
    selfHelpPayCost_20006: '如您的定制版本需在到期后继续使用，请提前联系我们。',
    selfHelpPayCost_20007: '每月1日将为您推送账单，收取上月费用',
    selfHelpPayCost_20008: '若您已开通第三方流量导流服务，只计算非流量订单数据',
    selfHelpPayCost_20009: '当前版本续费',
    selfHelpPayCost_20010: '保持当前平台版本资费不变，延长使用时间',
    selfHelpPayCost_20011: '立即升级版本',
    selfHelpPayCost_20012: '立即升级版本、扩容充电口',
    selfHelpPayCost_20013: '立即升级当前平台版本',
    selfHelpPayCost_20014: '升级当前平台版本或增加支持的充电口数量',
    selfHelpPayCost_20015: '付费方式',
    selfHelpPayCost_20016: '续费方式',
    selfHelpPayCost_20017: '手动续费',
    selfHelpPayCost_20018: '自动续费',
    selfHelpPayCost_20019: '平台版本',
    selfHelpPayCost_20020: '支持充电口',
    selfHelpPayCost_20021: '请选择',
    selfHelpPayCost_20022: '{num}个充电口',
    selfHelpPayCost_20023: '不限制',
    selfHelpPayCost_20024: '使用期限',
    selfHelpPayCost_20025: '定制版',
    selfHelpPayCost_20026: '续费规则',
    selfHelpPayCost_20027: '不开通自动续费',
    selfHelpPayCost_20028: '在您的平台使用期限到期前，系统会在到期日提前三天，按您设置的续费标准自动生成账单。',
    selfHelpPayCost_20029: '每次账单生成时将从账户余额中自动扣款，请确保账户余额充足。',
    selfHelpPayCost_20030: '设置后，您可以随时取消自动续费。',
    selfHelpPayCost_20031: '收费标准',
    selfHelpPayCost_20032: '收费周期',
    selfHelpPayCost_20033: '每月1日收取上月账单费用',
    selfHelpPayCost_20034: '开通后，次月1日将生成两笔账单（本月基础版平台使用费和本月高级版平台使用费）',
    selfHelpPayCost_20035: '设置开启，在您的平台使用期限到期前，系统会在到期日提前三天，按您设置的续费标准自动生成账单。',
    selfHelpPayCost_20036: '设置关闭，在您的平台使用期限到期时，将不再自动续费。',
    selfHelpPayCost_20037: '您有一笔账单待支付，支付后平台即可启动充电。',
    selfHelpPayCost_20038: '查看账单',
    selfHelpPayCost_20039: '如需定制服务，或有任何疑问，请联系我们：400-877-0227',
    selfHelpPayCost_20040: '立即开通',
    selfHelpPayCost_20041: '立即升级',
    selfHelpPayCost_20042: '立即续费',
    selfHelpPayCost_20043: '开启',
    selfHelpPayCost_20044: '关闭',
    selfHelpPayCost_20045: '{labelTxt}，{maxPortNum}充电口，剩余{count}天',
    selfHelpPayCost_20046: '{labelTxt}，按上月充电量，{monthBillPrice}/度收取',
    selfHelpPayCost_20047: '定制版，上月充电量超过{monthBillFree}度，超过部分按{monthBillPrice}/度收取。',
    selfHelpPayCost_20048: '{labelTxt}，按上月服务费，{monthBillPrice}%比例收取',
    selfHelpPayCost_20049: '定制版，上月服务费超过{monthBillFree}，超过部分按{monthBillPrice}%收取',
    selfHelpPayCost_20050: '个',
    selfHelpPayCost_20051: '半年({val}天)',
    selfHelpPayCost_20052: '一年({val}天)',
    selfHelpPayCost_20053: '{val}天',
    selfHelpPayCost_20054: '按半年({val}天)续费',
    selfHelpPayCost_20055: '按一年({val}天)续费',
    selfHelpPayCost_20056: '按{val}天续费',
    selfHelpPayCost_20057: '按上月充电量，{monthBillPrice}/度收取',
    selfHelpPayCost_20058: '按上月服务费，{monthBillPrice}%比例收取',
    selfHelpPayCost_20059: '请选择平台版本',
    selfHelpPayCost_20060: '请选择收费标准',
    selfHelpPayCost_20061: '请选择充电口数量',
    selfHelpPayCost_20062: '请选择使用期限',
    selfHelpPayCost_20063: '到期前3天内不可开启自动续费，请选择手动续费',
    selfHelpPayCost_20064: '升级后，您之前设置的自动续费将自动取消，如有需要请重新设置。',
    selfHelpPayCost_20065: '您确定开通版本',
    selfHelpPayCost_20066: '操作成功，平台版本已变更',
    selfHelpPayCost_20067: '自动续费设置失败',
    selfHelpPayCost_20068: '应付金额',
    selfHelpPayCost_20069: '尊享优惠',
    selfHelpPayCost_20070: '已优惠',
    selfHelpPayCost_20071: '折算金额',
    selfHelpPayCost_20072: '请输入内容',
    selfHelpPayCost_20073: '确认',
    selfHelpPayCost_20074: '更换优惠码',
    selfHelpPayCost_20075: '使用优惠码',
    selfHelpPayCost_20076: '购买后可用{0}天',
    selfHelpPayCost_20077: '合计',
    selfHelpPayCost_20078: '支付方式',
    selfHelpPayCost_20079: '账户余额支付',
    selfHelpPayCost_20080: '(余额：{0})',
    selfHelpPayCost_20081: '微信支付',
    selfHelpPayCost_20082: '升级后，您的<span>当前版本的剩余使用期限</span>将会替换成<span>升级后版本的使用期限</span>，您确定立刻升级吗？',
    selfHelpPayCost_20083: '账户余额{0}',
    selfHelpPayCost_20084: '立即支付',
    selfHelpPayCost_20085: '无需支付，直接升级',
    selfHelpPayCost_20086: '上一步',
    selfHelpPayCost_20087: '开通版本',
    selfHelpPayCost_20088: '恭喜您开通成功',
    selfHelpPayCost_20089: '升级版本',
    selfHelpPayCost_20090: '当前版本',
    selfHelpPayCost_20091: '恭喜升级成功',
    selfHelpPayCost_20092: '续费时长',
    selfHelpPayCost_20093: '剩余时长',
    selfHelpPayCost_20094: '恭喜您续费成功',
    selfHelpPayCost_20095: '基础版',
    selfHelpPayCost_20096: '高级版',
    selfHelpPayCost_20097: '物联网卡',
    selfHelpPayCost_20098: '平台使用费',
    selfHelpPayCost_20099: '一次性收费',
    selfHelpPayCost_20100: '账单已支付',
    selfHelpPayCost_20101: '优惠码无效，请重新输入',
    selfHelpPayCost_20102: '余额不足',
    selfHelpPayCost_20103: '请输入优惠码',
    selfHelpPayCost_20104: '免费',
    selfHelpPayCost_20105: '先付费',
    selfHelpPayCost_20106: '后付费',
    selfHelpPayCost_20107: '变更码',
    selfHelpPayCost_20108: '{labelTxt}，{maxPortNum}充电口，{dealerLicensePeriod}',
    selfHelpPayCost_20109: '{billType}，满{feeMatch}{currencyType}减免{dealerCoupon}{currencyType}',
    selfHelpPayCost_20110: '{currentLabelTxt}，{maxPortNum}充电口，剩余{count}天',
    selfHelpPayCost_20111: '提示：如果变更后的版本为先付费模式，需对平台使用费账单支付完成，即可变更成功',
    selfHelpPayCost_20112: '变更码无效，请重新输入',
    selfHelpPayCost_20113: '请输入变更码',
    selfHelpPayCost_20114: '操作成功',
    selfHelpPayCost_20115: '您有一笔平台使用费账单待支付',
    selfHelpPayCost_20116: '请先支付或关闭该账单',
    selfHelpPayCost_20117: '查看',
    selfHelpPayCost_20118: '您输入的优惠码不符合当前使用条件',
    selfHelpPayCost_20119: '当前充电口数量超出限制',
    selfHelpPayCost_20120: '您有一笔账单待支付，请先支付或取消该账单',
    selfHelpPayCost_20121: '请输入优惠码后点击确认或取消',
    // 功能对比
    versionCompare_00001: "功能对比",
    versionCompare_00002: "功能",
    versionCompare_00003: "基础版服务",
    versionCompare_00004: "简单实用，捕捉一切重要信息",
    versionCompare_00005: "高级版服务",
    versionCompare_00006: "最强功能，助力效率全面提升",
    versionCompare_00007: "微信公众号客户端",
    versionCompare_00008: "平台首页",
    versionCompare_00009: "整体概览",
    versionCompare_00010: "场站管理系统",
    versionCompare_00011: "充电订单管理",
    versionCompare_00012: "设备维保系统",
    versionCompare_00013: "微信用户管理",
    versionCompare_00014: "充电卡管理",
    versionCompare_00015: "刷充电卡充电",
    versionCompare_00016: "财务管理系统",
    versionCompare_00017: "用户客服",
    versionCompare_00018: "工单系统",
    versionCompare_00019: "行业CRM系统",
    versionCompare_00020: "用户营销系统",
    versionCompare_00021: "流量导流系统",
    versionCompare_00022: "数据分析系统",
    versionCompare_00023: "加盟商管理系统",
    selfHelpPayCost_20122: "{labelTxt}，按上月收费充电口数量收取",
    selfHelpPayCost_20123: "按上月收费充电口数量收取",
    selfHelpPayCost_20124: "查看收费标准",
    selfHelpPayCost_20125: "直流{fast}{currencyType}/充电口/月、交流{slow}{currencyType}/充电口/月",
    selfHelpPayCost_20126: "设备数量",
    selfHelpPayCost_20127: "直流{fast}个、交流{slow}个",
    selfHelpPayCost_20128: "按上月收费充电口数量",
    selfHelpPayCost_20129: "充电口数量",
    selfHelpPayCost_20130: "{0}元/充电口/月",
    selfHelpPayCost_20131: "{0}台",
    selfHelpPayCost_20132: "不满一个自然月，按天收取",
    selfHelpPayCost_20133: "自动扣费设置",
    selfHelpPayCost_20134: "含平台使用费{0}元",
    selfHelpPayCost_20135: "物联网卡网费{0}元",
    selfHelpPayCost_20136: "一次性收费{0}元",
    selfHelpPayCost_31000:"开票中",
    selfHelpPayCost_31001:"注意事项",
    selfHelpPayCost_31002: `1、平台使用费、物联卡网费每月1日出上月账单，请您在每月10日前完成支付。如您延期支付平台使用费，届时平台将停用且无法启动充电，为避免影响您的使用，请您按时缴费。<br/>
    2、支付方式：平台使用费、物联卡网费账单支持微信支付及公对公网银转账，如您选择微信支付，可直接在平台账单点击去支付按钮完成支付，也可以在账户余额进行微信充值后自动扣款。如您选择公对公网银转账需要先充值到账户余额，平台账单会自动从账户余额扣款。<br/>
    3、账户余额充值不支持开具发票，发票需要在账单支付成功后方可申请，如您需要开具与充值金额等额的发票，请务必在账户余额充值时，根据“自动扣费设置“中设置的收费项目的待支付的账单金额进行充值。<br/>
    4、发票开具：<br/>
    4.1、您在平台注册时提供的公司名称为您开票时的公司抬头，首次开票时需要您注意查看公司名称、 纳税人识别号信息，如有误，请在系统中心-信息设置-基本信息中进行修改后申请开具发票。<br/>
    4.2、平台上支持线上开具电子发票，目前平台统一开放的开票权限为：增值税普通发票，如您需开具增值税专用发票，请在首次申请前，联系您对接的智充业务同事提交“一般纳税人资格证明”进行资格审核后方可开具。<br/>
    5、人工联系方式：如您有任何问题可拨打010-53609116 ，时间：工作日早10：00点至晚18：00点；您也可向您对接的智充业务同事进行咨询。<br/>`,
    selfHelpPayCost_31003:"导出账单",
}