<template>
  <!-- pc端的菜单列表 -->
  <div :class="['menu-pc',{'w260':lang=='en'},{'w280':lang=='de'}]">
    <transition  name="slide-fade">
      <div v-show="!isCollapse" class="show-menu">
        <menu-list :isActive="isActive" />
      </div>
    </transition>
  </div>
</template>

<script>
import menuList from "./menuList.vue"
export default {
  data() {
    return {
      product: "cn",
      lang: "zh",
    };
  },
  components: {
    menuList
  },
  props: ["isActive","isShrink","isCollapse"],
  watch: {
    isActive: {
      handler(newValue, oldValue) {
        if(newValue) {
          this.activeMenu = this.isActive;
        }
      },
      immediate: true
    },
    isCollapse: {
      handler(newValue, oldValue) {
      },
      immediate: true
    }
  },
  created() {
    this.product = window.product_area || 'cn';
    this.lang = window.localStorage.lang;
  },
}
</script>
<style lang="scss" scoped>
.menu-pc{
  width: 200px;
  &.w260 {
    width: 260px;
  }
  &.w280{
    width:280px;
  }
  .show-menu{
    position:fixed;
    top:60px;
    left:0;
    bottom:0;
    background-color:#fff;
    padding-bottom: 40px;
    padding-top: 25px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
 .slide-fade-leave-active {
  transition: all 1s ease;
}
.slide-fade-enter, .slide-fade-leave-to  {
  opacity: 0;
  transform: translateX(-100%);
}

</style>