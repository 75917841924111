export default {
  workSchedule_00001: 'Time schedule name',
  workSchedule_00002: 'List',
  workSchedule_00003: 'New time schedule',
  workSchedule_00004: 'Time schedule name',
  workSchedule_00005: 'This work mode is not applied and can be deleted. Are you sure you want to delete?',
  workSchedule_00006: 'Retry',
  workSchedule_00007: 'Modify time schedule',
  workSchedule_00008: 'Duplicate time schedule',
  workSchedule_00009: 'Duplicate time Details',
  workSchedule_00010: '',
  workSchedule_00011: '',
}