export default {
  billEq_00001: "查看物联卡",
  billEq_00002: "账单信息",
  billEq_00003: "收费项目",
  billEq_00004: "物联卡数量",
  billEq_00005: "收费标准",
  billEq_00006: "收费时段",
  billEq_00007: "设备列表",
  billEq_00008: "充电桩名称",
  billEq_00009: "SN",
  billEq_00010: "场站",
  billEq_00011: "应收金额",
  billEq_00012: "实付金额",
  billEq_00013: "已开发票",
  billEq_00014: "未开发票",
  billEq_00015: "物联网卡费",
  billEq_00016: "平台使用费",
  billEq_00017: "一次性收费",
  billEq_00018: "{price}{currencyType}/卡/月，每月1日收取上月费用",
  billEq_00019: "共计 {0}台充电桩",
  billEq_00020: "已支付",
  billEq_00021: "已欠费",
  billEq_00022: "待支付",
  billEq_00023: "开票中",
  billEq_00024: "共{dataNum}个直流充电口，总计{feeTotal}{currencyType}",
  billEq_00025: "共{dataNum}个交流充电口，总计{feeTotal}{currencyType}",
  billEq_00026: "收费金额",
  selfHelpPayCost_10018: '去支付',
  selfHelpPayCost_10019: '去开票',
  selfHelpPayCost_10023: '收费标准',
  selfHelpPayCost_10024: '收费时段',
  selfHelpPayCost_10025: '应收金额',
  selfHelpPayCost_10026: '实付金额',
  selfHelpPayCost_10027: '优惠金额',
  selfHelpPayCost_10028: '折算金额',
  selfHelpPayCost_10029: '支付时间',
  selfHelpPayCost_10030: '支付方式',
  selfHelpPayCost_10041: '微信支付',
  selfHelpPayCost_10042: '账户余额支付',
  selfHelpPayCost_10064: '(优惠码{code}，',
  selfHelpPayCost_10065: '满{fee}元减{dis}元',
  selfHelpPayCost_10066: '(平台使用时间剩余{day}天)',
  selfHelpPayCost_10063: '账单支付超时，已无法支付，请关闭账单后重新购买',
  selfHelpPayCost_10054: '微信支付',
  selfHelpPayCost_00064: '支付成功',
  selfHelpPayCost_10058: '支付失败',
  selfHelpPayCost_10022: '收费版本',
  selfHelpPayCost_10038: '定制版',
  selfHelpPayCost_10039: '高级版',
  selfHelpPayCost_10040: '基础版',
  selfHelpPayCost_20125: "直流{fast}{currencyType}/充电口/月、交流{slow}{currencyType}/充电口/月",
  selfHelpPayCost_20126: "设备数量",
  selfHelpPayCost_20127: "直流{fast}个、交流{slow}个",
  selfHelpPayCost_10047: '{count}充电口，{dayDesc}({days}天)，{amount}{currencyType}',
  selfHelpPayCost_10048: '半年',
  selfHelpPayCost_10049: '一年',
  selfHelpPayCost_10050: '不限制充电口，{dayDesc}({days}天)，{amount}{currencyType}',
  selfHelpPayCost_10051: '按上月充电量，{amount}元/度，每月1日收取',
  selfHelpPayCost_10052: '按上月服务费，{amount}%比例，每月1日收取',
  selfHelpPayCost_10053: '上月服务费超过{amount}{currencyType}，超过部分按{price}%收取',
  selfHelpPayCost_10072: '上月充电量超过{amount}度，超过部分按{price}{currencyType}/度收取',
  selfHelpPayCost_20001: '直流',
  selfHelpPayCost_20002: '交流',
  selfHelpPayCost_20103: '请输入优惠码',
  selfHelpPayCost_20100: '账单已支付',
  selfHelpPayCost_20101: '优惠码无效，请重新输入',
  selfHelpPayCost_20102: '余额不足',
  selfHelpPayCost_20118: '您输入的优惠码不符合当前使用条件',
  selfHelpPayCost_00006: '元',
  selfHelpPayCost_00067: '下一步',
  selfHelpPayCost_20078: '支付方式',
  selfHelpPayCost_20079: '账户余额支付',
  selfHelpPayCost_20080: '(余额：{0})',
  selfHelpPayCost_20081: '微信支付',
  selfHelpPayCost_20070: '已优惠',
  selfHelpPayCost_20069: '尊享优惠',
  selfHelpPayCost_20074: '更换优惠码',
  selfHelpPayCost_10055: '使用优惠码',
  selfHelpPayCost_10056: '请输入优惠码',
  selfHelpPayCost_00009: '账单详情',
  selfHelpPayCost_10057: '卡号',
  selfHelpPayCost_10058: '物联卡列表',
  selfHelpPayCost_10059: "共计 {0} 张物联卡",
}