export default {
  userDetail_00001: "刷新微信信息",
  userDetail_00002: "男",
  userDetail_00003: "女",
  userDetail_00004: "修改",
  userDetail_00005: "保存",
  userDetail_00006: "取消",
  userDetail_00007: "用户微信公众号OpenID",
  userDetail_00008: "加入时间",
  userDetail_00009: "公众号",
  userDetail_000010: "已关注",
  userDetail_000011: "已取消关注",
  userDetail_000012: "最近一次充电时间",
  userDetail_000013: "微信支付免密支付",
  userDetail_000014: "已授权",
  userDetail_000015: "未授权",
  userDetail_000016: "绑定的车辆VIN",
  userDetail_000017: "充  值",
  userDetail_000018: "钱包余额",
  userDetail_000019: "元",
  userDetail_000020: "查看充值记录",
  userDetail_000021: "查看退款记录",
  userDetail_000022: "接受场站充电限制约束",
  userDetail_000023: "用户车辆",
  userDetail_000024: "车型",
  userDetail_000025: "车牌号",
  userDetail_000026: "加入的团体",
  userDetail_000027: "近30天的充电数据",
  userDetail_000028: "请保存上一步操作！！",
  userDetail_000029: "输入的手机号不能为空",
  userDetail_000030: "输入的车牌号不能为空",
  userDetail_000031: "请输入正确的格式:车辆品牌|型号",
  userDetail_000032: "请选择车辆品牌或车辆型号",
  userDetail_000033: "修改成功",
  userDetail_000034: "充电次数：",
  userDetail_000035: "充电电量：",
  userDetail_000036: "充电费用：",
  userDetail_000037: "次",
  userDetail_000038: "度",
  userDetail_000039: "选择品牌",
  userDetail_000040: "选择型号",
  userDetail_000041: "车品牌|型号",
  userDetail_000042: "手动输入",
  userDetail_000043: "非等级用户",
  userDetail_000044: "升级到{title}还差{num}",
  userDetail_000045: "保级{title}还差{num}",
  userDetail_000046: "无需保级任务",
  userDetail_000047: "赠送金额",
  userDetail_000048: "单位",
  userDetail_000049: "登录密码",
  userDetail_000050: "必填",
  userDetail_000051: "请输入密码",
  userDetail_000052: "备注",
  userDetail_000053: "必填，不超过20个字",
  userDetail_000054: "请输入内容",
  userDetail_000055: "确  定",
  userDetail_000056: "已加入用户黑名单",
  userDetail_000057: "用户中心",
  userDetail_000058: "基本信息",
  userDetail_000059: "拥有的卡券",
  userDetail_000060: "查询充电订单",
  userDetail_000061: "查询占用订单",
  userDetail_000062: "加入用户黑名单",
  userDetail_000063: "操作成功",
  userDetail_000064: "成长等级",
  userDetail_000065: "调整等级",
  userDetail_000066: "开",
  userDetail_000067: "关",
  userDetail_000068: "账户充值",
  userDetail_000069: "调整会员等级",
  userDetail_000070: "备注最多输入20个字",
  userDetail_000071: "请输入备注",
  userDetail_000072: "请输入充值金额或者赠送金额",
  userDetail_000073: "请输入正确的赠送金额",
  userDetail_000073: "请输入正确的充值金额",
  userDetail_000074: "加入用户黑名单",
  userDetail_000075: "加入用户黑名单后，用户的微信号和手机号以及相同手机号对应的其他微信号都不能给车辆充电！",
  userDetail_000076: "结束日期",
  userDetail_000077: "加入理由",
  userDetail_000078: "选择日期",
  userDetail_000079: "请输入加入黑名单的理由(必填)",
  userDetail_000080: "加 入",
  userDetail_000081: "理由不能为空！",
  userDetail_000082: "结束日期不能为空！",
  userDetail_000083: "含赠送{num}元",
  userDetail_000084: "含团体分配{num}{unit}元",
  userDetail_000085: "用户",
  userDetail_000086: "用户管理",
  userDetail_000087: "管理员",
  userDetail_000088: "解除绑定VIN",
  userDetail_000089: "昵称备注",
  userDetail_000090: "输入的昵称备注不能为空",
  userDetail_000091: "请绑定手机号",
  userDetail_000092: "未绑定手机号",
  userDetail_000093: "暂无加入的团体",
  userDetail_000094: "请输入密码",
  userDetail_000095: "请输入少于10个字的备注名称",
  userDetail_000096: "请输入备注，最多10个字",
  userDetail_000097: "用户来源",
  userDetail_000098: "状态",
  userDetail_000099: "名称",
  userDetail_000100: "有效期",
  userDetail_000101: "获得方式",
  userDetail_000102: "获取时间",
  userDetail_000103: "操作",
  userDetail_000104: "抵用券详情",
  userDetail_000105: "折扣券详情",
  userDetail_000106: "活动详情",
  userDetail_000107: "剩余额度",
  userDetail_000108: "电量包详情",
  userDetail_000109: "代金券详情",
  userDetail_000110: "抵用券",
  userDetail_000111: "折扣券",
  userDetail_000112: "电量包",
  userDetail_000113: "代金券",
  userDetail_000114: "全部",
  userDetail_000115: "待生效",
  userDetail_000116: "可使用",
  userDetail_000117: "已用完",
  userDetail_000118: "已失效",
  userDetail_000119: "邀请好友：邀请人奖励",
  userDetail_000120: "邀请好友：接受邀请的好友奖励",
  userDetail_000121: "度",
  userDetail_000122: "购买",
  userDetail_000123: "兑换",
  userDetail_000124: "保级任务已完成",
  userDetail_000125: "清空充值余额",
  userDetail_000126: "清空充值余额原因",
  userDetail_000127: "请输入清空充值余额原因",
  userDetail_000128: "清空",
  userDetail_000129: "当前充值余额：",
  userDetail_000130: "含赠送：",
  userDetail_000131: "特权会员",
  userDetail_000132: "开通会员",
  userDetail_000133: "开通记录",
  userDetail_000134: "到期",
  userDetail_000135: "批量作废卡券",
  userDetail_000136: "团体绑定的VIN",
  userDetail_000137: "特权会员绑定的VIN",
  userDetail_000138: "用户微信小程序OpenID",
  userDetail_000139: "微信公众号和微信小程序",
  userDetail_000140: "微信公众号",
  userDetail_000141: "微信小程序",
  
  privilegedMember_00015: '充电{type}{val}',
  privilegedMember_00016: '总费用',
  privilegedMember_00017: '服务费',
  privilegedMember_00018: '全免',
  privilegedMember_00019: '打 {0} 折',
  privilegedMember_00020: '开通特权会员启用',
  privilegedMember_00021: '不打折',
  siteStatus: "站点状态",
  isOpen: "对外开放",
  enable: "启用",
  enabled: "已启用",
  disabled: "未启用",
  chargeEquipment: "充电桩",

}