export default {
  workSchedule_00001: '自定义工作模式时间计划名称',
  workSchedule_00002: '列表',
  workSchedule_00003: '新建自定义工作模式时间计划',
  workSchedule_00004: '时间计划名称',
  workSchedule_00005: '该工作模式没有应用，可以删除。是否确定删除？',
  workSchedule_00006: '重试',
  workSchedule_00007: '修改自定义工作模式时间计划',
  workSchedule_00008: '复制自定义工作模式时间计划',
  workSchedule_00009: '自定义工作模式时间计划详情',
  workSchedule_00010: '',
  workSchedule_00011: '',
}