export default {
  deviceMonitor_00001: '占用中',
  deviceMonitor_00002: '设备尚未启用',
  deviceMonitor_00003: '设备离线',
  deviceMonitor_00005: '进入空闲',
  deviceMonitor_00006: '开始充电',
  deviceMonitor_00008: '预约中',
  deviceMonitor_00009: '暂无故障码',
  deviceMonitor_00010: "设备重启成功",
  deviceMonitor_00011: "远程重启",
  deviceMonitor_00012: "设备重启失败",
  deviceMonitor_00013: "设备管理",
  deviceMonitor_00014: "当前故障",
  deviceMonitor_00015: "故障处理",
  deviceMonitor_00016: "请输入设备名称或序列号",
  deviceMonitor_00017: "选择或输入充电站名称",
  deviceMonitor_00018: "全部",
  deviceMonitor_00019: "设备类型：",
  deviceMonitor_00020: "直流",
  deviceMonitor_00021: "交流",
  deviceMonitor_00022: "网关",
  deviceMonitor_00023: "故障",
  deviceMonitor_00024: "离线",
  deviceMonitor_00025: "空闲",
  deviceMonitor_00026: "仅看已插枪",
  deviceMonitor_00027: "使用中",
  deviceMonitor_00028: "仅看待拔枪",
  deviceMonitor_00029: "离线",
  deviceMonitor_00030: "未启用",
  deviceMonitor_00031: "设备序列号：",
  deviceMonitor_00032: "厂商-型号：",
  deviceMonitor_00033: "充电站：",
  deviceMonitor_00034: "重启设备",
  deviceMonitor_00035: "设备详情",
  deviceMonitor_00036: "充电口：",
  deviceMonitor_00037: "离线",
  deviceMonitor_00038: "未启用",
  deviceMonitor_00039: "故障",
  deviceMonitor_00040: "占用中",
  deviceMonitor_00041: "充电中",
  deviceMonitor_00042: "空闲",
  deviceMonitor_00043: "待挪车",
  deviceMonitor_00044: "预约中",
  deviceMonitor_00045: "已插枪",
  deviceMonitor_00046: "待拔枪",
  deviceMonitor_00047: "查看订单",
  deviceMonitor_00048: "重启充电口",
  deviceMonitor_00049: "设备重启指令已发送，重启过程大概需要5分钟。请停留在此页面，不要刷新页面。",
  deviceMonitor_00050: "提示信息",
  deviceMonitor_00051: "确 定",
  deviceMonitor_00052: "设备管理",
  deviceMonitor_00053: "当前故障",
  deviceMonitor_00054: "插枪",
  deviceMonitor_00055: "充电结束",
  deviceMonitor_00056: "未启用",
  deviceMonitor_00057: "正常",
  deviceMonitor_00058: "故障",
  deviceMonitor_00059: "锁车",
  deviceMonitor_00060: "休眠",
  deviceMonitor_00061: "未知",
  deviceMonitor_00062: "状态：",
  deviceMonitor_00063: "故障",
  deviceMonitor_00064: "仅看未归位",
  deviceMonitor_00065: "已归位",
  deviceMonitor_00066: "未归位",
  deviceMonitor_00067: "故障/离线",
  deviceMonitor_00068: "储能柜",
  deviceMonitor_00085: "双平台",
  deviceMonitor_00086: "添加设备",

  //自检
  eqDetails_30001: "桩端恢复急停状态",
  eqDetails_30002: "之后是否自动自检",
  eqDetails_30003: "硬件自检",
  eqDetails_30004: "1、检查设备硬件状态，过程需要几十秒",
  eqDetails_30005: "2、此检查要求充电桩的Android应用的固件版本号在4.0.0以上。",
  eqDetails_30006: "3、第三方设备不支持此检查。",
  eqDetails_30007: "上次检查结果",
  eqDetails_30008: "开始检查",
  eqDetails_30009: "自检中，请稍候",
  eqDetails_30010: "重新检查",
  eqDetails_30011: "检查时间",
  eqDetails_30012: "状态检查",
  eqDetails_30013: "硬件寿命",
  eqDetails_30014: "自检项",
  eqDetails_30015: "确认并恢复急停",
  eqDetails_30016: "设备使用中，自检取消，请空闲后操作",
  eqDetails_30017: "当前设备离线",
  eqDetails_30018: "设备自检超时，请重试",
  eqDetails_30020: "差",
  eqDetails_30021: "中",
  eqDetails_30022: "良",
  eqDetails_30023: "此检查要求充电桩的Android应用的固件版本号在4.0.0及以上",
  eqDetails_30024: "未查询到上次自检结果",
  eqDetails_30025: "立即恢复",
  eqDetails_30026: "先检查设备，再考虑恢复",
  eqDetails_30027: "免责申明",
  eqDetails_30028: "为了确保充电桩的安全运行，我们建议您在恢复急停按钮前进行硬件检查，如发现任何硬件故障或安全隐患，请停止使用充电桩，并联系我们的技术支持团队。我们将尽快提供解决方案，以确保您的充电桩能够安全、稳定地运行。",
  eqDetails_30029: "请注意，此免责申明旨在提醒您，在不确定充电桩状态地前提下，选择直接恢复急停按钮，会存在一定风险，我们将不承担任何由此产生的责任。",
  eqDetails_30030: "确定直接恢复",
  eqDetails_30031: "自检项",
  eqDetails_30032: "状态",
  eqDetails_30033: "自检取消，请检修后操作",
  eqDetails_30034: "生成并导出固定二维码",
  eqDetails_30035: "说明：固定二维码格式为：http://weixin.qq.com/q/xxxxxxxxx一次最多可导出100个，满额下载需要约20秒，下载成功前请不要关闭本页。",
  eqDetails_30036: "导出数量",
  eqDetails_30037: "生成并导出固定二维码",
  eqDetails_30038: "包含已生成未使用固定二维码",

}