export default {
    safetyLog_00001: "车辆VIN",
    safetyLog_00002: "车辆型号",
    safetyLog_00003: "全部场站",
    safetyLog_00004: "车牌号",
    safetyLog_00005: "车辆充电场站",
    safetyLog_00006: "电池容量保持率得分",
    safetyLog_00007: "最大压差偏离度得分",
    safetyLog_00008: "最大温差偏离度得分",
    safetyLog_00009: "车责异常得分",
    safetyLog_00010: "最高温度偏离度得分",
    safetyLog_00011: "评估结论",
    safetyLog_00012: "时间",
    safetyLog_00013: "车辆安全报告",
    safetyLog_00014: "事故备注",
    safetyLog_00015: "限制/禁止充电",
    safetyLog_00016: "低风险",
    safetyLog_00017: "中风险",
    safetyLog_00018: "高风险",
    safetyLog_00019: "请填写备注",
    safetyLog_00020: "车主",
    safetyLog_00021: "评估结论",
    safetyLog_00022: "全部",
  }