export default {
  alarmList_00001: "告警列表",
  alarmList_00002: "未处理",
  alarmList_00003: "标记已处理",
  alarmList_00004: "请输入车型编码",
  alarmList_00005: "请输入车辆VIN",
  alarmList_00006: "请输入场站名称",
  alarmList_00007: "事件类型",
  alarmList_00008: "实时防护",
  alarmList_00009: "提前预警",
  alarmList_00010: "告警等级",
  alarmList_00011: "严重警告",
  alarmList_00012: "提示告警",
  alarmList_00013: "时间",
  alarmList_00014: "车型",
  alarmList_00015: "车主",
  alarmList_00016: "更改告警状态为已处理，是否确认？",
  alarmList_00017: "事件",
  alarmList_00018: "车辆安全报告",
  alarmList_00019: "上次安全评估日志",
  alarmList_00020: "查看订单",
  alarmList_00021: "车牌号",
  alarmList_00022: "安全评估日志",
}