import constants from "./constants";
import dayjs from "dayjs";
var requestUrl = constants.requestUrl;
var bindWxUrl = constants.bindWxUrl;
var activateUrl = constants.activateUrl;
export { requestUrl, bindWxUrl, activateUrl };
var lang = window.localStorage.lang;//切换语言存localStorage,每次优先从localStorage取
if (!lang) {
  var language = navigator.language.slice(0, 2);
  if (language === "ch" || language === "en" || language === "de" || language === "es") {
    lang = language;
  } else {
    var product = constants.product_area;
    if (product == "eu") {
      lang = "en";
    } else {
      lang = "zh";
    }
  }
}
var offsetHour = parseInt(new Date().getTimezoneOffset() / 60); //单位小时
var offsetMinute = Math.abs(new Date().getTimezoneOffset() % 60);
var timezone = "";
if (offsetHour <= 0) {
  offsetHour = Math.abs(offsetHour);
  timezone =
    "+" +
    (offsetHour < 10 ? "0" + offsetHour : offsetHour) +
    ":" +
    (offsetMinute < 10 ? "0" + offsetMinute : offsetMinute);
} else {
  timezone =
    "-" +
    (offsetHour < 10 ? "0" + offsetHour : offsetHour) +
    ":" +
    (offsetMinute < 10 ? "0" + offsetMinute : offsetMinute);
}
//获取参数
export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return r[2];
  return "";
}
//ajax请求封装
export function ajax(options) {
  options = options || {};
  options.type = (options.type || "GET").toUpperCase();
  var params = options.data;

  //创建 - 非IE6 - 第一步
  if (window.XMLHttpRequest) {
    var xhr = new XMLHttpRequest();
  } else {
    //IE6及其以下版本浏览器
    var xhr = new ActiveXObject("Microsoft.XMLHTTP");
  }

  //接收 - 第三步
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      var status = xhr.status;
      if (status >= 200 && status < 300) {
        var info = JSON.parse(xhr.responseText);
        if (info.error != null) {
          if (info.error.code == "NOT_LOGIN") {
            window.location.href = "./login.html?l=0";
            return false;
          }
        }
        options.success && options.success(xhr.responseText, xhr.responseXML);
      } else {
        options.fail && options.fail(status);
      }
    }
  };
  //连接 和 发送 - 第二步
  var async = true; //默认异步
  if (options.async == false) {
    async = false;
  }
  var tmp = options.url;

  if (tmp.indexOf("?") > 0) {
    //        		tmp = options.url + "&r=" + Math.round(Math.random()*100000);
    tmp = options.url + "&";
  } else {
    //        		tmp = options.url + "?r=" + Math.round(Math.random()*100000);
    tmp = options.url + "?";
  }
  tmp = encodeURI(tmp);
  tmp =
    tmp +
    "lang=" +
    lang +
    "&timezone=" +
    encodeURIComponent(timezone) +
    "&r=" +
    Math.round(Math.random() * 100000);

  xhr.withCredentials = true;
  if (options.type == "GET") {
    xhr.open("GET", tmp, async);
    xhr.setRequestHeader("co-id", "xcharge");
    xhr.send(null);
  } else if (options.type == "POST") {
    xhr.open("POST", tmp, async);
    if (options.upType == "form") {
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    } else if ((options.upType = "file")) {
      //.  xhr.setRequestHeader("Content-Type", "multipart/form-data");
    } else {
      //设置表单提交时的内容类型
      xhr.setRequestHeader("Content-Type", "application/json");
      if (params != null && params != "") {
        params = params.replace(/\"true\"/g, "true");
        params = params.replace(/\"false\"/g, "false");
      }
    }
    xhr.setRequestHeader("co-id", "xcharge");
    xhr.send(params);
  } else if (options.type == "DELETE") {
    xhr.open("DELETE", tmp, async);
    xhr.setRequestHeader("co-id", "xcharge");
    xhr.send(null);
  }
}
//格式化参数
function formatParams(data) {
  var arr = [];
  for (var name in data) {
    arr.push(encodeURIComponent(name) + "=" + encodeURIComponent(data[name]));
  }
  arr.push(("v=" + Math.random()).replace(".", ""));
  return arr.join("&");
}
export function getCookie(name) {
  var cookieName = encodeURIComponent(name) + "=";
  var cookieStart = document.cookie.indexOf(cookieName);
  var cookieValue = null;
  if (cookieStart > -1) {
    var cookieEnd = document.cookie.indexOf(";", cookieStart);
    if (cookieEnd == -1) {
      cookieEnd = document.cookie.length;
    }
    cookieValue = decodeURIComponent(
      document.cookie.substring(cookieStart + cookieName.length, cookieEnd)
    );
  }
  return cookieValue;
}
export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() + -1 * 24 * 60 * 60 * 1000);
  var cval = getCookie(name);
  document.cookie = name + "= ''; expires=" + exp.toGMTString();
}
//调用myinfo接口
var LoginInfo = "";
export function getLoginInfo() {
  if (LoginInfo === "") {
    ajax({
      type: "GET",
      async: false,
      url: requestUrl + "/myinfo",
      dataType: "json",
      success: function (data) {
        var info = JSON.parse(data);
        if (info.error != null) {
          LoginInfo = "";
          window.location.href = "./login.html?l=0";
          return false;
        } else {
          LoginInfo = info;
        }
      }
    });
  }
  return LoginInfo.result;
}
export function checkIdType(type) {
  var idType = checkLogin("idType");
  return type === idType ? true : false;
}
export function checkLogin(openUrl) {
  if (LoginInfo != "") {
    if (openUrl == "name") {
      var obj = new Object();
      obj.loginId = LoginInfo.result.id;
      obj.name = LoginInfo.result.title;
      return obj;
    }
    if (openUrl == "contractAlert") {
      return LoginInfo.result.contractAlert;
    }
    if (openUrl == "id") {
      return LoginInfo.result.id;
    }
    if (openUrl == "wechatOASourceId") {
      return LoginInfo.result.wechatOASourceId;
    }
    if (openUrl == "imgUrl") {
      return LoginInfo.result.imageUrl;
    }
    if (openUrl == "idType") {
      return LoginInfo.result.idType;
    }
    if (openUrl == "roles") {
      return LoginInfo.result.roles;
    }
    if (openUrl == "dealerCreateTime") {
      return LoginInfo.result.dealerCreateTime;
    }
    if (openUrl == "employeeId") {
      return LoginInfo.result.employeeId;
    }
    if (openUrl == "wechatOARequired") {
      return LoginInfo.result.wechatOARequired;
    }
    if (openUrl == "permissions") {
      return LoginInfo.result.permissions;
    }
    if (openUrl == "permissionDeniedStyle") {
      return LoginInfo.result.permissionDeniedStyle;
    }
    if (openUrl == "permissionsV2") {
      return LoginInfo.result.permissionsV2
    }
    if (openUrl == "appId") {
      return LoginInfo.result.appId;
    }
    if (openUrl == "loginId") {
      return LoginInfo.result.loginId;
    }
    //运营商的货币类型
    if (openUrl == "currencyType") {
      return LoginInfo.result.currencyType;
    }
    //只有充电卡组账号才返回
    if (openUrl == "type") {
      return LoginInfo.result.type;
    }
    if (openUrl == "resources") {
      return LoginInfo.result.resources;
    }
    if (openUrl == "isSupervisor") {
      return LoginInfo.result.isSupervisor;
    }
    if (openUrl == "userGroupId") {
      return LoginInfo.result.userGroupId;
    }
  }
  var hasLogin = true;
  var id = "";
  var sourceId = "";
  var name = "";
  var isDealer = true;
  var cookieValue = getCookie("sid");
  ajax({
    type: "GET",
    async: false,
    url: requestUrl + "/myinfo",
    dataType: "json",
    success: function (data) {
      var info = JSON.parse(data);
      if (info.error != null) {
        LoginInfo = "";
        window.location.href = "./login.html?l=0";
        return false;
      } else {
        LoginInfo = info;
        hasLogin = true;
        if (info.result.idType != "dealerLevel1") {
          isDealer = false;
        }
      }
    }
  });
  if (hasLogin) {
    if (openUrl == null) {
      return true;
    } else {
      if (openUrl == "id") {
        return LoginInfo.result.id;
      }
      if (openUrl == "sourceId") {
        var obj = new Object();
        obj.sourceId = LoginInfo.result.wechatOASourceId;
        obj.isDealer = isDealer;
        return obj;
      }
      if (openUrl == "name") {
        var obj = new Object();
        obj.loginId = LoginInfo.result.id;
        obj.name = LoginInfo.result.title;
        return obj;
      }
      if (openUrl == "imgUrl") {
        return LoginInfo.result.imageUrl;
      }
      if (openUrl == "idType") {
        return LoginInfo.result.idType;
      }
      if (openUrl == "wechatOASourceId") {
        return LoginInfo.result.wechatOASourceId;
      }
      if (openUrl == "roles") {
        return LoginInfo.result.roles;
      }
      if (openUrl == "dealerCreateTime") {
        return LoginInfo.result.dealerCreateTime;
      }
      if (openUrl == "employeeId") {
        return LoginInfo.result.employeeId;
      }
      if (openUrl == "wechatOARequired") {
        return LoginInfo.result.wechatOARequired;
      }
      if (openUrl == "permissions") {
        return LoginInfo.result.permissions;
      }
      if (openUrl == "permissionsV2") {
        return LoginInfo.result.permissionsV2
      }
      if (openUrl == "appId") {
        return LoginInfo.result.appId;
      }
      if (openUrl == "loginId") {
        return LoginInfo.result.loginId;
      }
      if (openUrl == "type") {
        return LoginInfo.result.type;
      }
      //运营商的货币类型
      if (openUrl == "currencyType") {
        return LoginInfo.result.currencyType;
      }/*  else {
        window.location.href = "./" + openUrl;
      } */
    }
  } else {
    window.location.href = "./login.html";
  }
}
export function download(url, callback, error) {
  var xhh = new XMLHttpRequest();
  xhh.open("GET", url);
  xhh.responseType = 'blob';
  xhh.withCredentials = true;
  xhh.timeout = 900000;
  xhh.setRequestHeader("co-id", "xcharge");
  xhh.onreadystatechange = function () {
    if (xhh.readyState === 4 && xhh.status === 200) {
      callback();
      var filename = xhh.getResponseHeader("fileName");
      if (!filename) {
        var t = new Date().getTime();
        filename = t + ".xlsx";
      }
      var blob = new Blob([xhh.response], { type: 'text/xls' });
      console.info("b:" + blob.size);
      console.info("bn:" + blob.size);
      var csvUrl = URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = csvUrl;
      link.download = filename;
      link.click();
    }
    if (xhh.status > 400) {
      error();
    }
  };
  xhh.send();
};
export function send(url) {
  if (url.indexOf("?") > 0) {
    url += "&";
  } else {
    url += "?";
  }
  url = encodeURI(url);
  url =
    url +
    "lang=" +
    lang +
    "&timezone=" +
    encodeURIComponent(timezone) +
    "&r=" +
    Math.round(Math.random() * 100000);
  window.location.href = url;

}

//将long时间格式转成时间类型Date、
export function getDateFromLongTime(longTime) {
  longTime = "" + longTime;
  var str =
    longTime.substring(0, 4) +
    "/" +
    longTime.substring(4, 6) +
    "/" +
    longTime.substring(6, 8) +
    " " +
    longTime.substring(8, 10) +
    ":" +
    longTime.substring(10, 12) +
    ":" +
    longTime.substring(12, 14);
  return new Date(str);
}
//将本地时间转成东八区时间
export function getBjTimeFromLocalTime(longTime) {
  var localDate = getDateFromLongTime(longTime);
  var offSet = localDate.getTimezoneOffset() * 60 * 1000; //单位毫秒
  var bjOffSet = 8 * 60 * 60 * 1000; //毫秒差
  var gmtDate = new Date(localDate.getTime() + offSet);
  var time = new Date(gmtDate.getTime() + bjOffSet);

  return (
    "" +
    time.getFullYear() +
    (time.getMonth() + 1 < 10
      ? "0" + (time.getMonth() + 1)
      : time.getMonth() + 1) +
    (time.getDate() < 10 ? "0" + time.getDate() : time.getDate()) +
    (time.getHours() < 10 ? "0" + time.getHours() : time.getHours()) +
    (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()) +
    (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds())
  );
}
//通过北京时间计算客户端所在时区的时间 
export function getLocalTimeFromBjTime(longTime, hasH) {
  if (longTime == null || longTime == "") {
    return "*";
  }
  var bjTime = getDateFromLongTime(longTime);
  var offSet = new Date().getTimezoneOffset() * 60 * 1000; //单位毫秒
  var bjOffSet = 8 * 60 * 60 * 1000; //毫秒差
  var gmtDate = new Date(bjTime.getTime() - bjOffSet);
  var time = new Date(gmtDate.getTime() - offSet);
  var localTime = dayjs(time);
  var dayjslang = "en";
  if (lang) {
    if (lang == "zh") {
      dayjslang = "zh-cn";
    } else {
      dayjslang = lang;
    }
  }

  if (hasH) {
    if (!window.format.timeFormat[dayjslang]) {
      dayjslang = "en";
    }
    return localTime.format(window.format.timeFormat[dayjslang]);
  } else {
    if (!window.format.dateFormat[dayjslang]) {
      dayjslang = "en";
    }
    return localTime.format(window.format.dateFormat[dayjslang]);
  }
}