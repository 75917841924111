<template>
  <!-- mobile端的菜单列表 -->
  <div class="menu-mobile">
    <transition  name="slide-fade">
    <el-drawer
      :class="['m-drawer',{'w260':lang=='en'},{'w280':lang=='de'}]"
      :size="'100%'"
      :visible.sync="drawerVisible"
      :direction="direction"
      :with-header="false"
      :close-on-click-overlay="true"
      :before-close="handleClose"
      v-if="!isCollapse">
      <menu-list :isActive="isActive"/>
    </el-drawer>
    </transition>
  </div>
</template>
<script>
import menuList from "./menuList.vue"
export default {
  data() {
    return {
      product: 'cn',
      lang: 'zh',
      direction: 'ltr', // 方向
      drawerVisible: false, // 控制抽屉显示
    };
  },
  components: {
    menuList
  },
  props: ["isActive","isShrink","isCollapse",],
  watch: {
    isActive: {
      handler(newValue, oldValue) {
        if(newValue) {
        this.activeMenu = this.isActive;
        }
      },
      immediate: true
    },
    isCollapse: {
      handler(newValue, oldValue) {
        if(newValue) {
          this.drawerVisible = true; // 收起菜单时，隐藏抽屉
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClose(done) {
      this.$emit('collapse',true)
    },
  },
  created() {
    this.product = window.product_area || 'cn';
    this.lang = window.localStorage.lang;
  },
}
</script>

<style lang="scss" scoped>
// 移动端样式
.menu-mobile {
  // z-index: 3999;
  .m-drawer{
    // width: 230px!important;
    // transition: all 1s ease;//打开滑动时效
    &.el-drawer__wrapper{
      top: 60px;
    }
    &.w260 {
      /deep/.el-drawer{
        &.ltr{
          width: 260px!important;
        }
      }
    }
    &.w280 {
      /deep/.el-drawer{
        &.ltr{
          width: 280px!important;
        }
      }
    }
      /deep/.el-drawer{
      &.ltr{
        width: 200px!important;
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
 .slide-fade-leave-active {
  transition: all 1s ease;
}
.slide-fade-enter, .slide-fade-leave-to  {
  opacity: 0;
  transform: translateX(-100%);
}
</style>