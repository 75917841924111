export default {
  workSchedule_00001: '自定义工作模式时间计划名称',
  workSchedule_00002: 'Liste',
  workSchedule_00003: '新建自定义工作模式时间计划',
  workSchedule_00004: '时间计划名称',
  workSchedule_00005: '该工作模式没有应用，可以删除。是否确定删除？',
  workSchedule_00006: 'Wiederholung',
  workSchedule_00007: 'Modify time schedule',
  workSchedule_00008: 'Duplicate time schedule',
  workSchedule_00009: 'Duplicate time Details',
  workSchedule_00010: '',
  workSchedule_00011: '',
}