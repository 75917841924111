export default {
  safetyReportDetails_00001: '安全报告详情',
  safetyReportDetails_00002: '安全报告',
  safetyReportDetails_00003: '报告时间段',
  safetyReportDetails_00004: '编辑结论&建议',
  safetyReportDetails_00005: '导出为pdf',
  safetyReportDetails_00006: '发送给用户',
  safetyReportDetails_00007: '限制/禁止充电',
  safetyReportDetails_00008: '车牌号',
  safetyReportDetails_00009: 'VIN',
  safetyReportDetails_00010: '品牌车系年款',
  safetyReportDetails_00011: '查看用户',
  safetyReportDetails_00012: '充电行为分析',
  safetyReportDetails_00013: '启充SOC平均值',
  safetyReportDetails_00014: '结束SOC平均值',
  safetyReportDetails_00015: '快充次数',
  safetyReportDetails_00016: '慢充次数',
  safetyReportDetails_00017: '过放电次数 ',
  safetyReportDetails_00018: '车责异常安全日志数 ',
  safetyReportDetails_00019: '查看车责异常安全日志 ',
  safetyReportDetails_00020: '总安全日志数 ',
  safetyReportDetails_00021: '查看总安全评估日志 ',
  safetyReportDetails_00022: '车责异常安全日志占比',
  safetyReportDetails_00023: '充电异常分析',
  safetyReportDetails_00024: '充电订单占比大于20%，偏高',
  safetyReportDetails_00025: '安全得分分析',
  safetyReportDetails_00026: '事故备注',
  safetyReportDetails_00027: '最大温速变化趋势',
  safetyReportDetails_00028: '最大温度变化趋势',
  safetyReportDetails_00029: '最大温差变化趋势',
  safetyReportDetails_00030: '最大压差变化趋势',
  safetyReportDetails_00031: '电池容量保持率',
  safetyReportDetails_00032: '得分',
  safetyReportDetails_00033: '车责异常',
  safetyReportDetails_00034: '最高温度',
  safetyReportDetails_00035: '最大温差',
  safetyReportDetails_00036: '最大压差',
  safetyReportDetails_00037: '电池容量保持率',
  safetyReportDetails_00038: '该车电池{name}{time}的平均值是{caverage}，最高达到{max}，与您同城市相同车型年款的平均值是{oaverage}，可见该车电池{name}指标{evaluation}',
  safetyReportDetails_00039: '没有异常',
  safetyReportDetails_00040: '亚安全，但请注意保养车辆',
  safetyReportDetails_00041: '出现了明显异常，请尽快检修车辆',
  safetyReportDetails_00042: '同车系',
  safetyReportDetails_00043: '事故备注',
  safetyReportDetails_00044: '请填写备注',
  safetyReportDetails_00045: '您确定删除此条事故备注？',
  safetyReportDetails_00046: '起始SOC小于30%',
  safetyReportDetails_00047: '查看关联安全评估日志',
  safetyReportDetails_00048: '停充',
  safetyReportDetails_00049: '降功率',
  safetyReportDetails_00050: '告警',
  safetyReportDetails_00051: '无故障',
  safetyReportDetails_00052: '单项评估',
  safetyReportDetails_00053: '低风险',
  safetyReportDetails_00054: '中风险',
  safetyReportDetails_00055: '高风险',
  safetyReportDetails_00056: '车责异常',



  
  







    

    

    
}