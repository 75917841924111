import { render, staticRenderFns } from "./openFree.vue?vue&type=template&id=2af4807b&scoped=true&lang=html&"
import script from "./openFree.vue?vue&type=script&lang=js&"
export * from "./openFree.vue?vue&type=script&lang=js&"
import style0 from "./openFree.vue?vue&type=style&index=0&id=2af4807b&prod&lang=scss&scoped=true&"
import style1 from "./openFree.vue?vue&type=style&index=1&id=2af4807b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af4807b",
  null
  
)

export default component.exports