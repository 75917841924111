export default {
    safetyReportDetails_00001: 'Details zum Sicherheitsbericht',
    safetyReportDetails_00002: 'Sicherheitsbericht',
    safetyReportDetails_00003: 'Berichtszeitraum',
    safetyReportDetails_00004: 'Bearbeiten Schlussfolgerungen und Vorschläge',
    safetyReportDetails_00005: 'Als PDF exportieren',
    safetyReportDetails_00006: 'An Benutzer senden',
    safetyReportDetails_00007: 'Aufladung begrenzen/verbieten',
    safetyReportDetails_00008: 'Kennzeichen',
    safetyReportDetails_00009: 'VIN',
    safetyReportDetails_00010: 'Markenmodell-Jahr',
    safetyReportDetails_00011: 'Benutzer anzeigen',
    safetyReportDetails_00012: 'Analyse des Ladeverhaltens',
    safetyReportDetails_00013: 'Durchschnitt des SOC während des Ladevorgangs',
    safetyReportDetails_00014: 'Durchschnitt des SOC während der Endladung',
    safetyReportDetails_00015: 'Schnelle Ladezeiten',
    safetyReportDetails_00016: 'Langsame Ladezeiten',
    safetyReportDetails_00017: 'Überlastungszeiten ',
    safetyReportDetails_00018: 'Anzahl anormaler Transaktionen ',
    safetyReportDetails_00019: 'Abnormale Transaktionen anzeigen ',
    safetyReportDetails_00020: 'Gesamtzahl der Transaktionen ',
    safetyReportDetails_00021: 'Sicherheitsbewertungsprotokoll anzeigen ',
    safetyReportDetails_00022: 'Anteil anormaler Transaktionen',
    safetyReportDetails_00023: 'Analyse anormaler Ladevorgänge',
    safetyReportDetails_00024: 'Ladungsabnormal Termination Ratio ist hoch ',
    safetyReportDetails_00025: 'Safety Score Analyse',
    safetyReportDetails_00026: 'Bemerkungen zu Ereignissen',
    safetyReportDetails_00027: 'Trend zur Änderung der maximalen Temperaturrate',
    safetyReportDetails_00028: 'Trend zur maximalen Temperaturänderung',
    safetyReportDetails_00029: 'Änderungstrends der maximalen Temperaturdifferenz',
    safetyReportDetails_00030: 'Veränderungstrends des maximalen Differenzdrucks',
    safetyReportDetails_00031: 'Batteriekapazität konstant',
    safetyReportDetails_00032: 'Punktzahl',
    safetyReportDetails_00033: 'Temperaturgeschwindigkeit',
    safetyReportDetails_00034: 'Temperatur',
    safetyReportDetails_00035: 'Temperaturunterschied',
    safetyReportDetails_00036: 'Spannungsunterschied',
    safetyReportDetails_00037: 'Batteriekapazität konstant',
    safetyReportDetails_00038: 'Der Durchschnittswert der Batterie {Name} {Zeit} des Autos ist {Kaverage}, mit dem höchsten Wert {max}, und der Durchschnittswert des gleichen Modelljahres in Ihrer Stadt ist {oaverage}. Es ist zu erkennen, dass der Batterie {name} Index {Evaluation} des Fahrzeugs',
    safetyReportDetails_00039: 'Kein Problem',
    safetyReportDetails_00040: 'Bitte achten Sie auf die Wartung des Fahrzeugs',
    safetyReportDetails_00041: 'Es gibt offensichtliche Anomalien. Bitte reparieren Sie das Fahrzeug so schnell wie möglich',
    safetyReportDetails_00042: 'Gleiches Modell',
    safetyReportDetails_00043: 'Bemerkungen zu Ereignissen',
    safetyReportDetails_00044: 'Bitte füllen Sie Bemerkungen aus',
    safetyReportDetails_00045: 'Sind Sie sicher, diese Notiz zu löschen?',
    safetyReportDetails_00046: 'Start weniger als 30% soc',
    safetyReportDetails_00047: 'Generated a vehicle fault exception ', 
    safetyReportDetails_00048: 'Stop charging ', 
    safetyReportDetails_00049: 'Reduce power ', 
    safetyReportDetails_00050: 'Alarm ', 
    safetyReportDetails_00051: 'No fault ',
    safetyReportDetails_00052: 'Individual evaluation ', 
    safetyReportDetails_00053: 'Safe ', 
    safetyReportDetails_00054: 'Sub-safe ', 
    safetyReportDetails_00055: 'Unsafe ',
      
}
  