export default {
securityRealTimeProtection_00001: "今日触发防护车次",
securityRealTimeProtection_00002: "本月累计触发防护车次",
securityRealTimeProtection_00003: "今日停充充电车次",
securityRealTimeProtection_00004: "本月累计停充充电车次",
securityRealTimeProtection_00005: "今日拒充充电车次",
securityRealTimeProtection_00006: "本月累计拒充充电车次",
securityRealTimeProtection_00007: "严重告警（今日{num}}条）",
securityRealTimeProtection_00008: "提示告警（今日{num}条）",
securityRealTimeProtection_00009: "实时防护列表",
securityRealTimeProtection_00010: "严重告警",
securityRealTimeProtection_00011: "条",
securityRealTimeProtection_00012: "时间",
securityRealTimeProtection_00013: "车型",
securityRealTimeProtection_00014: "车牌号",
securityRealTimeProtection_00015: "场站",
securityRealTimeProtection_00016: "事件",
securityRealTimeProtection_00018: "【实时防护】停充",
securityRealTimeProtection_00019: "【实时防护】降功率",
securityRealTimeProtection_00020: "【提前预警】上次评估高风险",
securityRealTimeProtection_00021: "【提前预警】上次评估中风险",
securityRealTimeProtection_00022: "【提前预警】拒充-禁止充电",
securityRealTimeProtection_00023: "【提前预警】限充-限制充电",
securityRealTimeProtection_00024: "正常",
securityRealTimeProtection_00025: "提示告警",
securityRealTimeProtection_00026: "今日未处理告警",




}