export const list = [
  /* {
    title: "",
    children: [
      {
        explain: "",
        threshold: [],
        alarmCode: "",
        strategy: "",
      }
    ]
  }, */
  // 充电桩硬件检测
  {
    headTitle: "充电桩硬件检测",
    title: "待机枪头电压检验",
    children: [
      {
        explain: "一把枪启动充电时，检验别的未充电枪电压若高于(阈值)则中止充电流程。",
        threshold: [
          {
            value: 36.0,
            valMax: 80,
            valMin: 0,
            uint: 'V'
          }
        ],
        alarmCode: "D0101",
        strategy: "停充",
        edit: false
      }
    ]
  },
  {
    title: "熔断器断开检验",
    children: [
      {
        explain: "在绝缘检测阶段通过检测熔断器前后两端的电压，压差超过(阈值)认为是熔断器断路异常或者直接采用带反馈信号的熔断器，一旦检测到熔断器断路则告警且中止充电流程。",
        threshold: [
          {
            value: 10,
            valMax: 10,
            valMin: 2,
            uint: 'V'
          }
        ],
        alarmCode: "D0102",
        strategy: "停充",
        edit: false
      }
    ]
  },
  {
    title: "充电枪头过温",
    children: [
      {
        explain: "充电连接装置温度超过(阈值) 则开始降功率输出，当温度下降到安全阈值则开始增加输出功率；",
        threshold: [
          {
            value: 80,
            valMax: 90,
            valMin: 60,
            uint: '℃'
          }
        ],
        alarmCode: "D0104",
        strategy: "降功率",
        edit: false
      },
      {
        explain: "当充电连接装置温度超过阈值且持续时间超过阈值则停止充电；",
        threshold: [
          {
            value: 95,
            valMax: 100,
            valMin: 90,
            uint: '℃'
          },
          {
            value: 2,
            uint: 's'
          }
        ],
        alarmCode: "D0103",
        strategy: "停充",
        edit: false
      },
    ]
  },
  {
    title: "接触器拒动和误动",
    children: [
      {
        explain: "充电前检测接触器是否粘连，充电启动流程中检测接触器是否拒动，全程都需监测接触器误动。<br/>注：要求使用带主触点状态反馈的直流接触器，若使用IGBT电子开关需实现相应的检测（粘连，拒动，误动）",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0105",
        strategy: "告警",
      }
    ]
  },
  //充电桩检测车辆BMS数据
  {
    headTitle: "充电桩检测车辆BMS数据",
    title: "监测动力蓄电池状态信息",
    children: [
      {
        explain: "持续监测BSM字段并适时停止充电。<br/>持续监测BSM字段：动力蓄电池组输出连接器连接状态：不正常；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0201",
        strategy: "停充",
      },
      {
        explain: "持续监测BSM字段并适时停止充电。<br/>持续监测BSM字段：动力蓄电池绝缘转态：不正常；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0202",
        strategy: "停充",
      },
      {
        explain: "持续监测BSM字段并适时停止充电。<br/>持续监测BSM字段：动力蓄电池温度过高：过高；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0203",
        strategy: "停充",
      },
      {
        explain: "持续监测BSM字段并适时停止充电。<br/>持续监测BSM字段：动力蓄电池充电电流：过流；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0204",
        strategy: "停充",
      },
      {
        explain: "持续监测BSM字段并适时停止充电。<br/>持续监测BSM字段：整车动力蓄电池荷电状态SOC：过高或者过低；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0205",
        strategy: "停充",
      },
      {
        explain: "持续监测BSM字段并适时停止充电。<br/>持续监测BSM字段:单体动力蓄电池电压：过高或者过低；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0206",
        strategy: "停充",
      },
    ]
  },
  {
    title: "动力蓄电池充电总能量",
    children: [
      {
        explain: "当充电电量（从直流电表获取）超过“总能量*(1-初始SOC)* (阈值) ”时停止充电。",
        threshold: [
          {
            value: 1.05,
            valMax: 1.2,
            valMin: 1,
            uint: ''
          }
        ],
        alarmCode: "D0207",
        strategy: "停充",
        edit: false
      },
      {
        explain: "当充电电量（从直流电表获取）超过BCP报文中“动力蓄电池标称总能量”时停止充电。",
        threshold: [
          {
            value: 0,
            valMax: 10,
            valMin: 0,
            uint: 'kWh'
          }
        ],
        alarmCode: "D0208",
        strategy: "停充",
        edit: false
      },
    ]
  },
  {
    title: "监测充电电流测量值误差",
    children: [
      {
        explain: "BCS报文中“充电电流测量值”与充电桩输出电流测量值(>=30A时)之差绝对值超过 (阈值)%并持续(时间阈值)则停止充电。",
        threshold: [
          {
            value: 0.5,
            valMax: 2,
            valMin: 0.3,
            uint: 'A'
          },
          {
            value: 2,
            valMax: 10,
            valMin: 1,
            uint: 's'
          }
        ],
        alarmCode: "D0209",
        strategy: "告警",
        edit: false
      },
      {
        explain: "BCS报文中“充电电流测量值”与充电桩输出电流测量值(>=30A时)之差绝对值超过1%并持续 (阈值) 则停止充电。",
        threshold: [
          {
            value: 2,
            valMax: 2,
            valMin: 1,
            uint: '%'
          },
          {
            value: 2,
            valMax: 10,
            valMin: 1,
            uint: 's'
          }
        ],
        alarmCode: "D020A",
        strategy: "告警",
        edit: false
      },
    ]
  },
  {
    title: "监测充电电压测量值误差",
    children: [
      {
        explain: "充电机直流电表电压测量值和充电机高压板测量值差异绝对值超过(电压阈值) 并持续(时间阈值) 则告警。",
        threshold: [
          {
            value: 10,
            valMax: 20,
            valMin: 0,
            uint: 'V'
          },
          {
            value: 10,
            valMax: 30,
            valMin: 5,
            uint: 's'
          }
        ],
        alarmCode: "D020B",
        strategy: "告警",
        edit: false
      },
      {
        explain: "BCS报文中充电电压测量值”和充电机高压板测量值差异绝对值超过(电压阈值) 并持续(时间阈值) 则告警。",
        threshold: [
          {
            value: 10,
            valMax: 20,
            valMin: 0,
            uint: 'V'
          },
          {
            value: 10,
            valMax: 30,
            valMin: 5,
            uint: 's'
          }
        ],
        alarmCode: "D020C",
        strategy: "告警",
        edit: false
      },
      {
        explain: "BCS报文中“充电电压测量值”和充电机直流电表电压测量值差值绝对值超过(电压阈值) 并持续 (时间阈值) 则告警。",
        threshold: [
          {
            value: 10,
            valMax: 20,
            valMin: 0,
            uint: 'V'
          },
          {
            value: 10,
            valMax: 30,
            valMin: 5,
            uint: 's'
          }
        ],
        alarmCode: "D020D",
        strategy: "告警",
        edit: false,
      },
    ]
  },
  {
    title: "监测动力蓄电池温度",
    children: [
      {
        explain: "若BMT报文中电池组温度超过阈值则采取措施。",
        threshold: [
          {
            value: 55,
            valMax: 60,
            valMin: 50,
            uint: '℃'
          }
        ],
        alarmCode: "D020E",
        strategy: "降功率",
        edit: false,
      },
      {
        explain: "若BSM报文中电池组温度超过阈值则采取措施。",
        threshold: [
          {
            value: 55,
            valMax: 60,
            valMin: 50,
            uint: '℃'
          }
        ],
        alarmCode: "D020F",
        strategy: "降功率",
        edit: false,
      },
      {
        explain: "充电过程中持续监测并计算动力蓄电池温升速率，若温速率大于阈值则采取措施；",
        threshold: [
          {
            value: 5.0,
            valMax: 10,
            valMin: 1,
            uint: '℃/min'
          }
        ],
        alarmCode: "D0210",
        strategy: "降功率",
        edit: false,
      },
      {
        explain: "持续监测BMT报文中温度最高值是否超过BCP报文中“最高允许温度”，若是且持续时间超过阈值时间则立即停止充电；",
        threshold: [
          {
            value: 2,
            uint: 's'
          }
        ],
        alarmCode: "D0211",
        strategy: "停充",
      },
      {
        explain: "持续监测BSM报文中的“最高动力蓄电池温度”是否超过BCP报文中的“最高允许温度”，若是且持续时间超过阈值时间则立即停止充电；",
        threshold: [
          {
            value: 2,
            valMax: 10,
            valMin: 1,
            uint: 's',
          }
        ],
        alarmCode: "D0212",
        strategy: "停充",
        edit: false,
      },
    ]
  },
  {
    title: "监测动力蓄电池荷电状态",
    children: [
      {
        explain: "充电启动后，持续检测BCS报文中“当前荷电状态”是否超过100%或者平台限定的SOC上限，若是则立即停止充电；",
        threshold: [
          {
            value: 100,
            valMax: 100,
            valMin: 0,
            uint: '%'
          }
        ],
        alarmCode: "D0213",
        strategy: "停充",
        edit: false,
      },
      {
        explain: "在充电参数配置阶段判断BCP报文中“整车动力蓄电池荷电状态”是否超过100%或者平台限定的SOC上限，若是则停止充电启动流程；",
        threshold: [
          {
            value: 100,
            valMax: 100,
            valMin: 0,
            uint: '%'
          }
        ],
        alarmCode: "D0214",
        strategy: "停充",
        edit: false,
      },
      
    ]
  },
  {
    title: "监测单体电池电压极差",
    children: [
      {
        explain: "若电池类型为三元锂电池，持续监测充电过程中三元锂电池中单体最高电压与最低电池差，若大于 (阈值) 且持续10s则认为电芯不均衡严重需告警。",
        threshold: [
          {
            value: 200,
            valMax: 300,
            valMin: 50,
            uint: 'mV',
          }
        ],
        alarmCode: "D0215",
        strategy: "停充",
        edit: false,
      }
    ]
  },
  {
    title: "监测单体动力蓄电池最高允许电压",
    children: [
      {
        explain: "如果电池是三元锂电池，持续监测BMV报文中“最高单体动力蓄电池电压”，若超过(阈值) 则立即停止充电；",
        threshold: [
          {
            value: 4.25,
            valMax: 4.3,
            valMin: 4.0,
            uint: 'V',
          }
        ],
        alarmCode: "D0216",
        strategy: "停充",
        edit: false,
      },
      {
        explain: "持续监测BMV报文（如果汽车发送有效BMV的话）中“单体动力蓄电池电压”的最大值是否超过BCP报文中“单体动力蓄电池最高允许充电电压”，若是则立即停止充电；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0217",
        strategy: "停充",
      },
      {
        explain: "如果电池是三元锂电池，持续监测BCS报文中“最高单体动力蓄电池电压”，若超过阈值则立即停止充电；",
        threshold: [
          {
            value: 4.25,
            valMax: 4.3,
            valMin: 4.0,
            uint: 'V',
          }
        ],
        alarmCode: "D0218",
        strategy: "停充",
        edit: false,
      },
      {
        explain: "持续监测BCS报文中“最高单体动力蓄电池电压”是否超过BCP报文中“单体动力蓄电池最高允许充电电压”，若是则立即停止充电；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D0219",
        strategy: "停充",
      },
      
      
      
    ]
  },
  {
    title: "监测参数配置、预充阶段、充电过程中,动力蓄电池充电总电压",
    children: [
      {
        explain: "充电启动后，持续检测BCL报文中“电压需求”是否超过BCP报文中“最高允许充电总电压”，若是则立即停止充电；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D021A",
        strategy: "停充",
      },
      {
        explain: "充电桩预充阶段测量车辆电池电压值与BCP报文中“整车动力蓄电池当前电池电压”差异绝对值大阈值时则停止充电启动流程；",
        threshold: [
          {
            value: 8,
            valMax: 9,
            valMin: 5,
            uint: 'V',
          }
        ],
        alarmCode: "D021B",
        strategy: "告警",
        edit: false,
      },
      {
        explain: "在充电参数配置阶段判断BCP报文中“整车动力蓄电池当前电池电压”是否超过BCP报文中“最高允许充电总电压”，若是则停止充电启动流程；",
        threshold: [
          {
            value: 0
          }
        ],
        alarmCode: "D021C",
        strategy: "告警 ",
      },
    ]
  },
  //充电桩检测车辆BMS数据不更新
  {
    headTitle: "充电桩检测车辆BMS数据不更新",
    title: "BMS数据长时间不更新",
    children: [
      {
        explain: '充电过程中BSM数据"最高动力蓄电池温度"保持不变超过阈值时间则终止充电；此条适用于阈值SOC以下。',
        threshold: [
          {
            value: 10,
            valMax: 30,
            valMin: 1,
            uint: 'min'
          },
          {
            value: 80,
            valMax: 90,
            valMin: 1,
            uint: '%'
          }
        ],
        alarmCode: "D0301",
        strategy: "告警",
        edit: false
      },
      {
        explain: '充电过程中BCS数据"最高单体动力蓄电池电压"保持不变超过阈值时间则终止充电；此条适用于阈值SOC以下。',
        threshold: [
          {
            value: 3,
            valMax: 10,
            valMin: 1,
            uint: 'min'
          },
          {
            value: 80,
            valMax: 90,
            valMin: 1,
            uint: '%'
          }
        ],
        alarmCode: "D0302",
        strategy: "告警",
        edit: false
      },
      {
        explain: '充电过程中BCS数据"充电电流测量值"保持不变超过阈值时间则终止充电；此条适用于阈值SOC以上。',
        threshold: [
          {
            value: 30,
            valMax: 60,
            valMin: 1,
            uint: 'min'
          },
          {
            value: 80,
            valMax: 100,
            valMin: 50,
            uint: '%'
          }
        ],
        alarmCode: "D0303",
        strategy: "告警",
        edit: false
      },
      {
        explain: '充电过程中BCS数据"充电电压测量值"保持不变超过阈值时间则终止充电；此条适用于阈值SOC以下。',
        threshold: [
          {
            value: 10,
            valMax: 20,
            valMin: 1,
            uint: 'min'
          },
          {
            value: 80,
            valMax: 90,
            valMin: 10,
            uint: '%'
          }
        ],
        alarmCode: "D0304",
        strategy: "告警",
        edit: false
      },
      {
        explain: '充电过程中BCS数据"当前荷电状态SOC"保持不变超过阈值时间则终止充电；此条适用于阈值SOC以下。',
        threshold: [
          {
            value: 4,
            valMax: 10,
            valMin: 1,
            uint: 'min'
          },
          {
            value: 80,
            valMax: 100,
            valMin: 1,
            uint: '%'
          }
        ],
        alarmCode: "D0305",
        strategy: "停充",
        edit: false
      },
    ]
  },
];