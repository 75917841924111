<template lang="html">
<el-dialog :title="dialogTitle" :visible.sync="visible" :lock-scroll="false" width="580px" :before-close="close">
  <div v-if="!contentFlag" class="prompt">
    <p v-html="messageData.message"></p>
  </div>
  <div v-if='contentFlag' class="content">
    <!-- 地图 -->
    <div class="page">
      <div class="input-name">
        <div class="name-title">{{$t('diversionDetail_00080')}}</div>
        <div class="flex">
          <el-input class="title-inp" v-model="titleInp"/>
          <span class="info"><span>*</span>{{$t("diversionDetail_00081")}}</span>
        </div>
      </div>
      <div v-if="type!=0" class="input-name">
        <div class="name-title">{{$t('diversionDetail_00082')}}</div>
        <div>
          <el-radio class="radio" v-model="outsideOwnerChargeDisabled" :label="false">{{$t('diversionDetail_00068')}}</el-radio>
          <el-radio class="radio" v-model="outsideOwnerChargeDisabled" :label="true">{{$t('diversionDetail_00069')}}</el-radio>
        </div>
      </div>
      <div v-if="type!=0" class="input-name">
        <div class="name-title">{{$t('diversionDetail_00012')}}</div>
        <div class="flex">
          <span v-if="enabled" class="enabled">{{$t("diversionDetail_00013")}}</span>
          <span v-else class="disabled">{{$t("diversionDetail_00014")}}</span>
        </div>
      </div>
      <div v-if="type!=0" class="input-name">
        <div class="name-title">{{$t('diversionDetail_00083')}}</div>
        <div class="flex">
          <span v-if="forbidPubUser" class="enabled">{{$t("diversionDetail_00068")}}</span>
          <span v-else class="disabled">{{$t("diversionDetail_00069")}}</span>
        </div>
      </div>
      <!-- <div v-if="type!=0" class="input-name">
        <div class="name-title">{{$t('diversionDetail_00129')}}</div>
        <div>
          <el-radio class="radio" v-model="pushActualPower" :label="true">{{$t('diversionDetail_00068')}}</el-radio>
          <el-radio class="radio" v-model="pushActualPower" :label="false">{{$t('diversionDetail_00069')}}</el-radio>
        </div>
      </div> -->
      <ul class="config_list">
        <li v-for="val,key in currentConfig" :key="key">
          <span class="label">{{val.label}} ({{key}})</span>
          <el-input class="inp" v-model="val.value"></el-input>
        </li>
      </ul>
      
    </div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="close">{{$t('diversionDetail_00084')}}</el-button>
    <el-button v-if="messageData.tag=='know'&&!contentFlag" type="primary" @click="toUpdate">{{$t('diversionDetail_00085')}}</el-button>
    <el-button v-if="messageData.tag=='id'&&!contentFlag" type="primary" @click="toSite">{{$t('diversionDetail_00086')}}</el-button>
    <el-button v-if="contentFlag" type="primary" @click="save">{{$t('common_00217')}}</el-button>
  </span>
</el-dialog>
</template>

<script>
import {
  requestUrl,
  getQueryString,
  ajax,
  checkLogin,
  checkIdType,
  getLoginInfo,
} from "@/assets/utils/network";
export default {
  props: {
    visible: Boolean,
    outsideownerId: String,
    siteId: String,
    title: String,
    type: Number,
    forbidPubUser: Boolean,
    enabled: Boolean,
    messageData: Object,
    currentConfig: Object, // 配置项
  },

  data() {
    return {
      outsideOwnerChargeDisabled: false,
      dialogTitle: "",
      contentFlag: false,
      titleInp: "",
      // pushActualPower: true,
    };
  },
  methods: {
    // 提示信息---去填写
    toSite() {
      let obj = {
        id: this.messageData.id,
        activeName: "basicInfo",
      };
      window.localStorage.setItem("item", JSON.stringify(obj));
      window.open("./detailDepot.html?id=" + this.messageData.id);
      this.close();
    },
    // 提示信息---我知道了
    toUpdate() {
      this.contentFlag = true;
    },
    // 编辑+保存内容
    close() {
      this.$emit("close", false);
      // this.pushActualPower = true;
    },
    saveA() {
      if (this.titleInp === "") {
        this.$message.error("diversionDetail_00087");
        return;
      }
      let temp = Object.keys(this.currentConfig);
      if (temp.some((it) => !this.currentConfig[it].value)) {
        this.$message.error(this.$t("detailDepot_30011"));
        return false;
      }
      //发布站点到第三方平台
      const obj = {
        siteId: this.siteId,
        outsideownerId: this.outsideownerId,
        title: this.titleInp,
      };
      if (temp.length) {
        obj.customConfig = new Object();
        temp.forEach((it) => {
          obj.customConfig[it] = this.currentConfig[it].value;
        });
      }
      var om = this;
      ajax({
        type: "POST",
        url: requestUrl + "/outsideowner/devicegroup",
        dataType: "json",
        data: JSON.stringify(obj),
        success: function (response) {
          var info = JSON.parse(response);
          if (info.error != null) {
            om.$message({
              message: info.error.msg,
              type: "warning",
            });
            return;
          } else {
            om.close();
            om.$emit("confirm");
            om.$message.success(om.$t("diversionDetail_00088"));
          }
        },
      });
    },
    saveB() {
      if (this.titleInp === "") {
        this.$message.error("diversionDetail_00087");
        return;
      }
      let temp = Object.keys(this.currentConfig);
      if (temp.some((it) => !this.currentConfig[it].value)) {
        this.$message.error(this.$t("detailDepot_30011"));
        return false;
      }
      //发布站点到第三方平台
      const obj = {
        siteId: this.siteId,
        outsideownerId: this.outsideownerId,
        title: this.titleInp,
        outsideOwnerChargeDisabled: this.outsideOwnerChargeDisabled,
        // pushActualPower: this.pushActualPower,
      };
      if (temp.length) {
        obj.customConfig = new Object();
        temp.forEach((it) => {
          obj.customConfig[it] = this.currentConfig[it].value;
        });
      }
      var om = this;
      ajax({
        type: "POST",
        url: requestUrl + "/outsideowner/devicegroup",
        dataType: "json",
        data: JSON.stringify(obj),
        success: function (response) {
          var info = JSON.parse(response);
          if (info.error != null) {
            om.$message({
              message: info.error.msg,
              type: "warning",
            });
            return;
          } else {
            om.close();
            om.$emit("confirm");
            om.$message.success(om.$t("diversionDetail_00088"));
          }
        },
      });
    },
    // 保存
    save() {
      if (this.type == 0) {
        this.saveA();
      } else {
        this.saveB();
      }
    },
  },
  created: function () {
    this.titleInp = this.title;
    if (this.messageData.tag) {
      this.dialogTitle = this.$t("diversionDetail_00089");
      this.contentFlag = false;
    } else {
      this.contentFlag = true;
      this.dialogTitle = this.$t("diversionDetail_00090");
    }
  },
};
</script>
<style lang="scss" scoped>
.prompt {
  color: #1b2330;
  font-size: 14px;
  p {
    width: 80%;
    margin: 0 auto;
    line-height: 2;
  }
}
.content {
  max-height: 600px;
  overflow-y: auto;
  .input-name {
    display: flex;
    min-height: 63px;
    border-bottom: 1px dashed #e6e7ec;
    & + .input-name {
      align-items: center;
    }
    &:last-of-type {
      border-bottom: none;
      align-items: center;
    }
    .name-title {
      width: 32%;
      color: #464b5c;
      font-size: 14px;
    }
    .flex {
      display: flex;
      flex-direction: column;
      .title-inp {
        width: 83%;
      }
      .info {
        display: inline-block;
        color: #8087a0;
        font-size: 12px;
        padding: 10px 0 20px 0;
        font-style: normal;
        > span {
          color: #ff302c;
          padding-right: 5px;
        }
      }
    }
  }
}
.enabled {
  display: inline-block;
  line-height: 30px;
  background: url(../../../assets/img/success.png) no-repeat left center;
  background-size: 20px 20px;
  padding-left: 25px;
}
.disabled {
  display: inline-block;
  line-height: 30px;
  background: url(../../../assets/img/error.png) no-repeat left center;
  background-size: 20px 20px;
  padding-left: 25px;
}
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.el-button + .el-button {
  margin-left: 20px;
}

.config_list {
  li {
    display: flex;
    margin-top: 20px;
    // flex-direction: column;
    .label {
      display: block;
      margin-bottom: 10px;
      margin-top: 20px;
      line-height: 1;
      text-align: left;
      width: 260px;
      margin: 0 auto;
      position: relative;
      padding-left: 10px;
      box-sizing: border-box;
      &::before {
        content: "*";
        color: red;
        position: absolute;
        left: 0;
        font-size: 20px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
// .page {
//   text-align: left;
// }
// .input-name {
//   width: 500px;
//   .name-title {
//     font-size: 16px;
//     color: #333;
//     margin: 15px 0;
//     span {
//       font-size: 14px;
//       color: #999;
//       margin-left: 16px;
//     }
//   }
//   .flex {
//     display: flex;
//     align-items: center;
//     padding: 10px 0;
//     padding-left: 0px;
//     background: #eee;
//     padding-left: 15px;
//     p {
//       color: #333;
//       font-size: 16px;
//       margin-right: 20px;
//     }
//     .el-input {
//       width: 250px;
//     }
//   }
//   .state {
//     p {
//       padding-left: 15px;
//       line-height: 24px;
//       text-align: left;
//       .name {
//         display: inline-block;
//         width: 180px;
//       }
//       .enabled {
//         display: inline-block;
//         line-height: 30px;
//         background: url(../../../assets/img/success.png) no-repeat left center;
//         background-size: 20px 20px;
//         padding-left: 25px;
//       }
//       .disabled {
//         display: inline-block;
//         line-height: 30px;
//         background: url(../../../assets/img/error.png) no-repeat left center;
//         background-size: 20px 20px;
//         padding-left: 25px;
//       }
//     }
//   }
//   .info {
//     font-size: 14px;
//     color: #666;
//     padding-top: 5px;
//   }
//   .buttons {
//     margin-top: 40px;
//     text-align: center;
//   }
// }
</style>
