export default {
    begin_date_select_remind: "选择开始日期",
    end_date_select_remind: "选择结束日期",
    until: "至",
    enter_activity_explain:"请输入活动说明",
    no_limit:"不限制",
    limit:"限制",
    single_user_charge_amount:"单个用户累计充电总费用满足",
    user_charge_amount:"单个用户累计充电量满足",
    only_no_charged:"只能是从未在平台上充过电的用户",
    userLevel:"用户等级",
    userClass:"用户分类",
    promotion_type1: "充值赠送(现金)",
    promotion_type2: "充值赠送(现金+优惠券)",
    promotion_type3: "充值赠送(优惠券)",
    promotion_type4: "赠送优惠券",
    promotion_type5: "销售优惠券",
    cycle_type1:"次/活动全程",
    cycle_type2:"次/每月",
    cycle_type3:"次/每周",
    cycle_type4:"次/每日",
    default_userLevel:"非等级用户",
    mouth_1:"1个月",
    mouth_2:"3个月",
    mouth_3:"12个月",
    promotion_date:"活动期间",
    promotion_invoice_1:"请输入少于20个字的活动名称",
    promotion_invoice_2:"请输入活动开始时间",
    promotion_invoice_3:"请输入活动结束时间",
    promotion_invoice_4:"请输入正确的活动时间",
    promotion_invoice_5:"请选择活动对象",
    promotion_invoice_6:"请输入少于150个字的活动名称",
    promotion_invoice_7:"充值金额/赠送金额输入多于4位",
    promotion_invoice_8:"单个用户充值成功次数输入有误",
    promotion_invoice_9:"单个用户累计充电总费用输入有误",
    promotion_invoice_10:"所有用户累计领取成功次数输入有误",
    promotion_invoice_11:"赠送现金总额输入有误",
    promotion_invoice_14:"最多支持6个档位",
    promotion_invoice_15:"确认删除此活动档位",
    enter_amount:"请输入金额",
    week:"1星期",
    enter_charge_placeholder:"请输入电量",
    support_refund:"支持退款",
    no_refund:"不支持退款",
    enter_success_times_1:"请输入充值成功次数",
    enter_success_times_2:"请输入领取成功次数",
    enter_discount_count:"请输入赠送现金总额",

    enter_coupon_name:"请输入优惠券名称",
    query: "查询",
    confirm: "确认",
    coupon_title:"卡券名称",
    coupon_type:"卡券类型",
    common_00241: "添加成功",
    wechat_user: "微信用户",
    confirm_delete_activate:"确认删除此活动",
    delete_success: "删除成功",

    validity:"有效期",
    operation: "操作",
    activity_detail: "详情",
    voucher_coupon:"抵用券",
    discount_coupon:"折扣券",
    day:"天",
    select_coupon_invoice_1:"最多选择6种优惠券",
    select_coupon_invoice_2:"最多选择1种优惠券",
    less_than_or_equal_to:"小于等于",
    greater_than_or_equal_to:"大于等于",
    promotion_invoice_17:"单个用户累计充电总费用满足活动期间{equal}{feeAmount}元",
    promotion_invoice_12:"单个用户累计充电总费用满足{feeAmountPeriod}个月{equal}{feeAmount}元",
    promotion_invoice_24:"单个用户累计充电量满足活动期间{equal}{powerAmount}度",
    promotion_invoice_21:"单个用户累计充电量输入有误",
    promotion_invoice_22:"单个用户累计充电量满足{powerAmountPeriod}个月{equal}{powerAmount}度",
    promotion_invoice_23:"单个用户累计充电量满足7天{equal}{powerAmount}度",
    modify_success: '修改成功',

    count_time:"统计时间",
    count_coupons:"卡券发放总数",
    get_coupon_people:"领取总人数",
    get_coupon_count:"领取总次数",
    provide_coupon:"卡券发放情况",
    all_coupons_count:"累计发放数量",
    fee_total:"总费用",

    use_condition:"使用条件",
    used:"已用完数量",
    unused:"可使用数量",
    expired: "已失效数量",
    user_collection_detail:"用户领取情况",
    head_portrait: "头像",
    nickname: "昵称",
    sex: "性别",
    user_mobile:"用户名(手机号)",
    card_number: "卡号",
    collection_time:"领取时间",
    gifts_coupon_amount:"赠送券额",
    gifts_coupon_length:"赠送卡券数量",
    u3_card: "在线身份卡",
    promotion_use_coupon_condition:"单次充电{msg}满足{feeAtLeast}元",
    service_fee: "服务费",
    promotion_amount:"赠送总金额(元)",
    amount_balance:"充值总金额(元)",
    promotion_charge_people:"充值总人数",
    promotion_charge_count:"充值总次数",
    user_charge_detail:"用户储值情况",
    charge_time:"储值时间",
    remind: "提示",
    export_data: "导出数据",
    coupon_detail:"卡券详情",
    user_detail:"用户详情",
    cancel: "取消",
    start_download: "开始下载",
    cancel_download: "取消下载",
    type: '用户类型',
    promotion_invoice_16:"请输入少于500个字的活动说明",
    level_check_info:"请选择用户等级",




    stored_00001: '详情',
    stored_00002: '营销中心',
    stored_00003: '营销活动',
    stored_00004: '新建',
    common_00271: '修改',
    common_00255: '基本信息',
    stored_00007: '活动信息',
    stored_00008: '活动名称',
    stored_00009: '活动时间',
    stored_00010: '活动对象',
    stored_00011: '微信用户',
    stored_00012: '活动档位',
    stored_00013: '添加档位',
    stored_00014: '（至少添加1个档位,最多支持6个档位）',
    stored_00015: '档位',
    stored_00016: '储值金额(元)',
    stored_00017: '赠送金额(元)',
    stored_00018: '赠送卡券',
    stored_00019: '操作',
    stored_00020: '修改',
    stored_00021: '单用户领取成功次数',
    stored_00021_1: '删除档位',
    stored_00022: '所有用户累计领取成功次数',
    stored_00023: '单用户储值成功次数',
    stored_00024: '赠送现金总额',
    stored_00025: '储值金额退款',
    stored_00026: '参与活动用户范围',
    stored_00027: '进行中',
    stored_00028: '已暂停',
    stored_00029: '取消',
    stored_00030: '保存',
    stored_00031: '修改',
    stored_00032: '删除',
    stored_00033: '活动效果',
    stored_00034: '活动说明',
    stored_00035: '活动ID',
    stored_00036: '赠送条件',
    stored_00037: '赠送条件类型',
    stored_00038: '充电量',
    stored_00039: '服务费',
    stored_00040: '订单实付金额',
    stored_00041: '单用户赠送最多次数',
    stored_00042: '请输入最多赠送次数',
    stored_00043: '所有用户累计赠送最多次数',
    stored_00044: '总充电量(度)',
    stored_00045: '服务费总金额(元)',
    stored_00046: '订单实付总金额(元)',
    stored_00047: '订单总数',
    stored_00048: '充电总人数',
    stored_00049: '赠送卡券总数',
    stored_00050: '赠送时间',
    stored_00051: '用户赠送情况',
    stored_00052: '全天',
    stored_00053: '限制每日生效时间段',
    stored_00054: '生效时间段',
    stored_00055: '添加时段',
    stored_00056: '不能设置0-24小时连续生效时间段',
    stored_00057: '时间段重复',
    stored_00058: '平台会按照订单开始时间和结束时间是否都在同一连续生效时段内判断是否生效。',
    stored_00059: '活动充值合同',
    stored_00060: '参与方式',
    stored_00061: '用户主动参与',
    stored_00062: '管理员手动赠送',
    stored_00063: '您确定导出数据？',


    coupon_00027: '可用站点',
    all_site:"全部站点",
    self_run_site:"自营站点",
    custom_site:"自定义站点",
    coupon_00028: '站点名称',
    coupon_00029: '查询',
    coupon_00030: '批量移除',
    add_site:"添加站点",
    site_name: "站点名称",
    operator: "运营商",
    coupon_00031: '操作',
    coupon_00032: '移除',
    enter_site:"输入站点名、运营商名",
    query: "查询",
    confirm: "确认",
    siteStatus: "站点状态",
    enabled: "已启用",
    disabled: "未启用",
    open: "开放",
    unopen: "不开放",
    isOpen: "对外开放",
    chargeEquipment: "充电桩",
    ac:"交",
    dc:"直",
    member_00072: '取消',
    member_00073: '添加',
    coupon_error_message_10:"请选择站点",



}

