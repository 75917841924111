export default {
    detailDepot_00001: '场站详情',
    detailDepot_00002: "乘用车",
    detailDepot_00003: "物流车",
    detailDepot_00004: "中巴车",
    detailDepot_00005: "大巴车",
    detailDepot_00006: "电单车",
    detailDepot_00007: "公共",
    detailDepot_00008: "个人",
    detailDepot_00009: "公交（专用）",
    detailDepot_00010: "环卫（专用）",
    detailDepot_00011: "物流（专用）",
    detailDepot_00012: "出租车（专用）",
    detailDepot_00013: '分时租赁（专用）（互联互通标准扩展）',
    detailDepot_00014: '小区共享（专用）（互联互通标准扩展）',
    detailDepot_00015: '单位（专用）（互联互通标准扩展）',
    detailDepot_00016: '其他',
    detailDepot_00017: '请选择',
    detailDepot_00018: '居民区',
    detailDepot_00019: '公共机构',
    detailDepot_00020: '机关企事业单位',
    detailDepot_00021: '写字楼',
    detailDepot_00022: '工业园区',
    detailDepot_00023: '交通枢纽',
    detailDepot_00024: '大型文体社区',
    detailDepot_00025: '城市绿化',
    detailDepot_00026: '大型建筑配建停车场',
    detailDepot_00027: '路边停车位',
    detailDepot_00028: '城际高速服务区',
    detailDepot_00029: '请参照推荐国标GB/T 2260-2007填写',
    detailDepot_00030: '编辑成功',
    detailDepot_00031: '国家编码格式错误(2位大写字母)',
    detailDepot_00032: '图片修改成功',
    detailDepot_00033: '上传图片',
    detailDepot_00034: '仅支持PNG、JPG、GIF格式图片，不超过1M。',
    detailDepot_00035: '实景图片',
    detailDepot_00036: '地址、街道、房屋名称',
    detailDepot_00037: '查 找',
    detailDepot_00038: '名 称',
    detailDepot_00039: '详细地址',
    detailDepot_00040: '当前位置',
    detailDepot_00041: '取 消',
    detailDepot_00042: '使用此位置',
    detailDepot_00043: '选择充电站位置坐标',
    detailDepot_00044: '您修改的地图位置与地址不吻合，误差在200米以上！',
    detailDepot_00045: "提 示",
    detailDepot_00046: "我知道了",
    detailDepot_00047: "地址未找到",
    detailDepot_00048: "高德地图发生未知错误",
    detailDepot_00049: "场站中心",
    detailDepot_00050: "场站管理",
    detailDepot_00051: '场站信息',
    detailDepot_00052: '设备管理',
    detailDepot_00053: '资费设置',
    detailDepot_00054: '运营设置',
    detailDepot_00055: '流量开放',
    detailDepot_00056: '政府对接',
    detailDepot_00057: '功率分配',
    detailDepot_00058: '公交车有序充电',
    detailDepot_00059: '基本信息',
    detailDepot_00060: '保 存',
    detailDepot_00061: '编 辑',
    detailDepot_00062: '删除场站',
    detailDepot_00063: '场站名称',
    detailDepot_00064: '场站位置',
    detailDepot_00065: '地图位置',
    detailDepot_00066: '省市辖区编码',
    detailDepot_00067: '国家代码',
    detailDepot_00068: '支持车型',
    detailDepot_00069: '场站类型',
    detailDepot_00070: '场站联系人',
    detailDepot_00071: '建筑场所',
    detailDepot_00072: '车位地点',
    detailDepot_00073: '车位数量',
    detailDepot_00074: '实景图片',
    detailDepot_00075: '姓名',
    detailDepot_00076: '手机号',
    detailDepot_00077: '邮箱',
    detailDepot_00078: '停车费',
    detailDepot_00079: '设备离线',
    detailDepot_00080: '开始充电',
    detailDepot_00081: '在线',
    detailDepot_00082: '离线',
    detailDepot_00083: '故障',
    detailDepot_00084: '充电中',
    detailDepot_00085: "全部",
    detailDepot_00086: "进入空闲",
    detailDepot_00087: "设备尚未启用",
    detailDepot_00088: "占用中",
    detailDepot_00089: "语言",
    detailDepot_00090: "时区",
    detailDepot_00091: "夏令时",
    detailDepot_00092: "交流桩电磁锁",
    detailDepot_00093: "直流桩电磁锁",
    detailDepot_00094: "电磁锁上锁失败能否充电",
    detailDepot_00095: "车位雷达",
    detailDepot_00096: "交流设备等待时间",
    detailDepot_00097: "直流设备等待时间",
    detailDepot_00098: "延时费收取最高金额",
    detailDepot_00099: "延时费收取策略",
    detailDepot_00100: "交流设备",
    detailDepot_00101: "直流设备",
    detailDepot_00102: "交流设备",
    detailDepot_00103: "直流设备",
    detailDepot_00104: "已启用",
    detailDepot_00105: "未启用",
    detailDepot_00106: "不可以充电",
    detailDepot_00107: "延时费收取策略",
    detailDepot_00108: "任何情况下都收取",
    detailDepot_00109: "因桩故障结束充电不收取",
    detailDepot_00110: "{num}分钟",
    detailDepot_00111: "设置为0表示不限制",
    detailDepot_00112: "可以充电",
    detailDepot_00113: "10分钟",
    detailDepot_00114: "15分钟",
    detailDepot_00115: "20分钟",
    detailDepot_00116: "30分钟",
    detailDepot_00117: "若设备离线，只支持白名单内的用户启动充电。",
    detailDepot_00118: "推送白名单",
    detailDepot_00119: "清除白名单",
    detailDepot_00120: "查看白名单",
    detailDepot_00121: "否",
    detailDepot_00122: "是",
    detailDepot_00123: "输入格式错误",
    detailDepot_00124: "不能输入负数",
    detailDepot_00125: "推送失败",
    detailDepot_00126: "共推送了{allDevice}台设备，{sucDevice}台成功，{faiDevice}台失败",
    detailDepot_00127: "无此设备",
    detailDepot_00128: "{num}个设备",
    detailDepot_00129: "编辑配置信息",
    detailDepot_00130: "C1充电桩(4.3寸)",
    detailDepot_00131: "C1充电桩(7寸)",
    detailDepot_00132: "C2充电桩",
    detailDepot_00133: "C4充电桩",
    detailDepot_00134: "C6充电桩",
    detailDepot_00135: "C6s充电桩",
    detailDepot_00136: "{0}上报故障，故障码：{1}",
    detailDepot_00137: "延时费收取最高金额格只能为数字",
    detailDepot_00251: "C9Pro充电桩",
    zh: "中文",
    en: "英文",
    de: "德语",
    fr: "法语",
    detailDepot_00138: "设备重启成功",
    detailDepot_00139: "远程重启",
    detailDepot_00140: "设备重启失败",
    processing: '重启中',
    detailDepot_00141: "请选择png或jpg格式的图片上传",
    detailDepot_00142: "最多上传6张",
    detailDepot_00143: "您所选的图片过大，请重新选择",
    detailDepot_00144: "上传图片",
    detailDepot_00145: "多张图片轮播或者mp4视频方式，二选一",
    detailDepot_00146: "使用图片轮播",
    detailDepot_00147: "点击上传",
    detailDepot_00148: "使用视频",
    detailDepot_00149: "5秒",
    detailDepot_00150: "10秒",
    detailDepot_00151: "15秒",
    detailDepot_00152: "最多上传6张",
    detailDepot_00153: "最多上传1个视频",
    detailDepot_00154: "您所选的视频过大，请重新选择",
    detailDepot_00155: "仅支持PNG、JPG、GIF格式图片，不超过{size}M。",
    detailDepot_00156: "完成",
    detailDepot_00157: "修改成功",
    detailDepot_00158: "修改位置",
    detailDepot_00159: "请参照推荐国标GB/T 2260-2007填写",
    detailDepot_00160: "请参照ISO 3166-1 alpha-2填写",
    detailDepot_00161: "请保存上一步操作！",
    detailDepot_00162: "参数为空",
    detailDepot_00163: "请输入正确的金额（大于0）",
    detailDepot_00164: "日期设置不正确",
    detailDepot_00165: "请输入国家编码",
    detailDepot_00166: "国家编码格式错误(2位大写字母)",
    detailDepot_00167: "请选择站点类型",
    detailDepot_00168: "请输入站点车位数目",
    detailDepot_00169: "车位数目格式输入错误",
    detailDepot_00170: "车位数目超过限制(<=10000)",
    detailDepot_00171: "停车费描述不能超过200字",
    detailDepot_00172: "请输入正确的客服电话",
    detailDepot_00173: "最多只能输入20个数字",
    detailDepot_00174: "请选择用户类型并设置百分比",
    detailDepot_00175: "请输入小于100的正整数",
    detailDepot_00176: "确认",
    detailDepot_00177: "取消",
    detailDepot_00178: "删除成功",
    detailDepot_00179: "操作已取消",
    detailDepot_00180: "确定删除该场站吗?",
    detailDepot_00181: "共{num}张",
    detailDepot_00182: "站点支持白名单",
    detailDepot_00183: "推送结果",
    detailDepot_00184: "共推送了{allDevice}台设备，{sucDevice}台成功，{faiDevice}台失败",
    detailDepot_00185: "设备编号",
    detailDepot_00186: "设备名称",
    detailDepot_00187: "交流资费标准",
    detailDepot_00188: "选择资费",
    detailDepot_00189: "新建交流资费",
    detailDepot_00190: "直流资费标准",
    detailDepot_00191: "新建直流资费",
    detailDepot_00192: "当前使用资费：",
    detailDepot_00193: "开始时间",
    detailDepot_00194: "结束时间",
    detailDepot_00195: "充电电费\n({type}/{unit})", //电费({type}/度)'
    detailDepot_00196: "服务费\n({type}/{unit})",
    detailDepot_00197: "延时费\n({type}/分钟)",
    detailDepot_00198: "谷峰平时段",
    detailDepot_00199: '谷峰平时段',
    detailDepot_00200: '未定义',
    detailDepot_00201: '尖',
    detailDepot_00202: '峰',
    detailDepot_00203: '平',
    detailDepot_00204: '谷',
    detailDepot_00205: '资费名称',
    detailDepot_00206: '选 择',
    detailDepot_00207: '新建资费',
    detailDepot_00208: '请输入资费名称',
    detailDepot_00209: '该资费名称已被占用',
    detailDepot_00210: '添加时段',
    detailDepot_00211: '最多可以添加{0}个时段',
    detailDepot_00212: '操 作',
    detailDepot_00213: '删 除',
    detailDepot_00214: '取 消',
    detailDepot_00215: '确 定',
    detailDepot_00216: '请选择或新建资费标准',
    detailDepot_00217: '最多可以添加12个时段',
    detailDepot_00218: '请填写资费标准',
    detailDepot_00219: '资费时段需从0开始至24结束',
    detailDepot_00220: '请填写正确的资费标准',
    detailDepot_00221: '新建成功',
    detailDepot_00222: '推送资费',
    detailDepot_00223: '站点名称',
    detailDepot_00224: '直流',
    detailDepot_00225: '交流',
    detailDepot_00226: '成 功',
    detailDepot_00227: '推送中',
    detailDepot_00228: '失 败',
    detailDepot_00229: '设备数量',
    detailDepot_00230: '设备推送结果',
    detailDepot_00231: '团体名称',
    detailDepot_00232: '当前资费标准',
    detailDepot_00233: '当前优惠折扣',
    detailDepot_00234: '优先级(由高到低)',
    detailDepot_00235: '上 移',
    detailDepot_00236: '下 移',
    detailDepot_00237: '设 置',
    detailDepot_00238: '推 送',
    detailDepot_00239: '删 除',
    detailDepot_00240: '单次充电总费用不打折',
    detailDepot_00241: '单次充电服务费不打折',
    detailDepot_00242: '单次充电总费用{des}折',
    detailDepot_00243: '单次充电服务费{des}折',
    detailDepot_00244: '单次充电服务费全免',
    detailDepot_00245: '单次充电总费用全免',

    detailDepot_00246: '删 除',
    detailDepot_00247: '删 除',
    detailDepot_00248: '删 除',
    detailDepot_00249: '删 除',
    detailDepot_00250: '删 除',


    /* 功率分配 */
    detailDepot_00401: "可以添加功率分配组，将站内充电桩点击添加到组中，按时段设置组内限制充电总功率，组内充电桩的充电功率从组内各个时段限制总充电功率分配且不超出各个时段的组内限制充电总功率。",
    detailDepot_00402: "添加功率分配组",
    detailDepot_00403: "有{count}台充电桩需推送！",
    detailDepot_00404: "为支持离线充电桩功率分配，需推送充电口离线最大充电功率{0}到充电桩。",
    detailDepot_00405: "推送离线功率配置",
    detailDepot_00406: "站内未分配的充电桩",
    detailDepot_00407: "您确定删除此功率分配组？",
    detailDepot_00408: "点击添加到功率分配组",
    detailDepot_00409: "请输入功率分配组名称",
    detailDepot_00410: "功率分配组名称",

    // 运营设置 ---基本配置
    abled: "",
    disabled: "",
    // modify:"修改",
    // save:"保 存",
    // cancel:"取 消",
    // confirm:"确定",
    // delete:"删除",

    detailDepot_00499: "只允许特定用户充电",
    detailDepot_00500: "场站启用状态",
    detailDepot_00501: "对外开放",
    detailDepot_00502: "是否支持优惠券",
    detailDepot_00503: "是否支持排队",
    detailDepot_00504: "是否支持预约",
    detailDepot_00505: "插枪超时后是否允许充电",
    detailDepot_00506: "微信用户付费模式",
    detailDepot_00507: "在线身份卡用户付费模式",
    detailDepot_00508: "充电站SOC限制",
    detailDepot_00509: "场站剩余电费提醒",
    detailDepot_00510: "控制站点是否在微信公众号中显示",
    detailDepot_00511: "影响站点在客户端的展示信息",
    detailDepot_00512: "启动后可允许团体资费设置中的团体用户充电",
    detailDepot_00513: "预付费模式时，只有充值用户才可以充",
    detailDepot_00514: "预付费模式时，只有充值用户才可以充电",
    detailDepot_00515: "只针对直流充电桩",
    detailDepot_00516: "当剩余电费<提醒额度，将进行提醒",
    detailDepot_00517: "请输入小于100的正整数",
    detailDepot_00518: "请选择用户类型",
    detailDepot_00519: "已启用",
    detailDepot_00520: "未启用",
    detailDepot_00521: "开放",
    detailDepot_00522: "不开放",
    detailDepot_00523: '是',
    detailDepot_00524: '否',
    detailDepot_00525: "已开放",
    detailDepot_00526: "未开放",
    detailDepot_00527: "支持",
    detailDepot_00528: "不支持",
    detailDepot_00529: "参数为空",
    detailDepot_00530: "只允许支持的团体充电",
    detailDepot_00531: "等待时间",
    detailDepot_00532: "分钟",
    detailDepot_00533: "预付费模式",
    detailDepot_00534: "后付费模式",
    detailDepot_00535: "余额最低",
    detailDepot_00536: "第三方用户",
    detailDepot_00537: "微信用户",
    detailDepot_00538: "当电池电量达到",
    detailDepot_00539: "% 时，自动结束充电",
    detailDepot_00540: "每度电价",
    detailDepot_00541: "元",
    detailDepot_00542: "提醒额度",
    detailDepot_00543: "剩余电费",
    detailDepot_00544: "请输入正确的金额（大于0）",
    detailDepot_00545: "请保存上一步操作！！",
    detailDepot_00546: "基本设置",

    // 运营设置 ---标签管理
    detailDepot_00547: '标签管理',
    detailDepot_00548: '添加自定义标签',
    detailDepot_00549: '添加微信公众号中站点标签，可在微信公众号中查找站点及站点详情中展示',
    detailDepot_00550: '标签名',
    detailDepot_00551: '标签图',
    detailDepot_00552: '标签类型',
    detailDepot_00553: '展示顺序',
    detailDepot_00554: "下移",
    detailDepot_00555: "上移",
    detailDepot_00556: "自定义标签",
    detailDepot_00557: "站点标签信息",
    detailDepot_00558: "标签名",
    detailDepot_00559: "标签类型",
    detailDepot_00560: "自定义标签",
    detailDepot_00561: "标签图",
    detailDepot_00562: "将文件拖到此处，或|点击上传",
    detailDepot_00563: "有标签图时，前端展示标签图。无标签图时，前端系统默认样式展示标签名。",
    detailDepot_00564: "仅支持PNG、JPG格式图片，尺寸{area}px，不超过{size}kb。",
    detailDepot_00565: "确定删除吗？",
    detailDepot_00566: "提示",
    custom: "自定义",
    undefined: "未定义",
    detailDepot_00567: "操作成功",
    detailDepot_00568: "操作失败",
    detailDepot_00569: "删除成功",
    detailDepot_00570: "已取消删除",
    detailDepot_00571: "修改成功",
    detailDepot_00572: "请输入内容，最多支持5个字",

    // 运营设置 ---充电订单拓展
    detailDepot_00573: "充电订单拓展",
    detailDepot_00574: "添加微信公众号中充电订单的展示信息",
    detailDepot_00575: "添加信息",
    detailDepot_00576: "标题",
    detailDepot_00577: "展示链接",
    detailDepot_00578: "展示限制",
    detailDepot_00579: '展示顺序',
    // common_00282:'操作',
    detailDepot_00580: "充电订单展示信息",
    detailDepot_00581: "展示内容",
    detailDepot_00582: "仅已支付的订单",
    detailDepot_00583: "不限订单状态",
    detailDepot_00584: "描述",
    detailDepot_00585: "请输入URL地址",
    detailDepot_00586: "勾选此项，则只在支付完成后展示此信息。",
    detailDepot_00587: "请填写内容",
    detailDepot_00588: "请输入内容",
    detailDepot_00589: "不超过{num}个字",
    detailDepot_00590: "请输入大于0的数字！",
    detailDepot_00591: "请输入占用费收费标准！",
    detailDepot_00592: "请输入免收占用费时长！",
    detailDepot_00593: "请输入正整数",

    // 运营设置 ---云控车位锁占用费
    detailDepot_00594: "云控车位锁占用费",
    detailDepot_00595: "占用费收费标准({type}/{unit})",
    detailDepot_00596: "免收占用费时长（分钟）",
    detailDepot_00597: "用于车主驾车驶入准备充电或挪出车位等无需收取占用费的时长",
    detailDepot_00598: "在线身份卡",
    // detailDepot_00598: "在线身份卡",


    // 停车场对接
    detailDepot_00600: "停车场对接",
    detailDepot_00601: "停车场对接是为了给用户减免停车费或发放停车优惠券",
    detailDepot_00602: "1、查找本站点所在停车场系统是否已经对接过",
    detailDepot_00603: "2、如果已经对接过，则输入停车场编号，关联该停车场。（停车场系统和停车场编号请与所在停车场确认）",
    detailDepot_00604: "3、如果没有对接过，可以联系商务，申请对接。",
    detailDepot_00605: "已对接的停车系统品牌介绍",
    detailDepot_00606: "关联",
    detailDepot_00607: "已关联停车场",
    detailDepot_00608: "停车场ID",
    detailDepot_00609: "关闭关联",
    detailDepot_00610: "请输入停车场id(只支持数字、字母和下划线)",
    detailDepot_00611: "过滤充电时间小于10分钟的订单",
    detailDepot_00612: "确认关闭跟{name}的关联?",
    detailDepot_00613: "再想想",
    detailDepot_00614: "确定",
    detailDepot_00615: "关联停车场",
    detailDepot_00616: "提示信息",
    detailDepot_00617: "不能为空",
    detailDepot_00618: "停车场id只支持数字、字母和下划线",
    // 流量开放+政府对接
    detailDepot_00619: "此处只展示了运营商已对接的政府流量，如需接入更多流量方，请与平台联系。 更多接入说明请访问：",
    detailDepot_00620: "政府平台",
    detailDepot_00621: "此处只展示了免费流量和运营商已对接的流量，如需接入更多流量方，请与平台联系。 更多接入说明请访问：",
    detailDepot_00622: "流量中心",
    detailDepot_00623: "免费",
    detailDepot_00624: "政府",
    detailDepot_00625: "直签",
    detailDepot_00626: "智充总包",
    detailDepot_00627: "该流量方已兼容智充二维码，需要在充电桩详情中升级为固定二维码",
    detailDepot_00628: "该流量方不兼容智充二维码，需要打印二维码粘贴使用",
    detailDepot_00629: "批量导出二维码",
    detailDepot_00630: "资费设置",
    detailDepot_00631: "站点折扣",
    detailDepot_00632: "启停设置",
    detailDepot_00633: "关闭开放",
    detailDepot_00634: "同意开放",
    detailDepot_00635: "已开放-不启停",
    detailDepot_00636: "已开放-可启停",
    detailDepot_00637: "未开放",
    detailDepot_00638: "已开放",
    detailDepot_00639: "站点无启用的充电桩，请启用至少一个充电桩。",
    detailDepot_00640: "站点信息中的",
    detailDepot_00641: "国家代码、",
    detailDepot_00642: "省市辖区编码、",
    detailDepot_00643: "停车费、",
    detailDepot_00644: "站点类型、",
    detailDepot_00645: "建筑场所、",
    detailDepot_00646: "车位数量、",
    detailDepot_00647: "客服电话、",
    detailDepot_00648: "实景图片、",
    detailDepot_00649: "为空",
    detailDepot_00650: "请将【站点详情】-【站点信息】-【状态信息】中“是否对外开放”设置为“开放”。",
    detailDepot_00651: "充电站",
    detailDepot_00652: "智充",
    // *******
    diversionDetail_00021: '操作',
    diversionDetail_00011: '运营商',
    diversionDetail_00060: '第三方流量平台',
    diversionDetail_00012: '站点状态',
    diversionDetail_00013: '已启用',
    diversionDetail_00014: '未启用',
    diversionDetail_00068: '是',
    diversionDetail_00069: '否',
    diversionDetail_00072: '返回',
    diversionDetail_00073: '修改成功',
    diversionDetail_00074: '请输入数字',
    diversionDetail_00075: '运营商给予智充的服务费折扣(%)',
    diversionDetail_00076: '单次充电总费用不打折（%）',
    diversionDetail_00077: '单次充电服务费打折（%）',
    diversionDetail_00078: '85表示8.5折，0表示全免',
    diversionDetail_00079: '单次充电按每度电优惠',
    diversionDetail_00080: '显示名称',
    diversionDetail_00081: '请输入显示名称，推荐格式：品牌名充电站（站点名称），站点名称中需要去除“品牌名”的关键词',
    diversionDetail_00082: '是否允许第三方启停桩',
    diversionDetail_00083: '只允许特定用户充电',
    diversionDetail_00084: '再想想',
    diversionDetail_00085: '我知道了',
    diversionDetail_00086: '去填写',
    diversionDetail_00087: '名称不能为空',
    diversionDetail_00088: '开放成功',
    diversionDetail_00089: '提示信息',
    diversionDetail_00090: '填写信息',
    diversionDetail_00091: '确定',
    diversionDetail_00092: '是否允许第三方启停桩',
    diversionDetail_00093: '直签资费设置',
    diversionDetail_00094: '如果资费标准有修改，在站点详情-资费标准中点击“推送资费”按钮，资费才可以生效！',
    diversionDetail_00095: '交流资费',
    diversionDetail_00096: '选择交流资费标准',
    diversionDetail_00097: '直流资费',
    diversionDetail_00098: '选择直流资费标准',
    diversionDetail_00099: '站点折扣设置',
    diversionDetail_00100: '添加站点折扣',
    diversionDetail_00101: '开始时间',
    diversionDetail_00102: '现在',
    diversionDetail_00103: '结束时间',
    diversionDetail_00104: '不限时',
    diversionDetail_00105: '优惠方式',
    diversionDetail_00106: '85表示8.5折',
    diversionDetail_00107: '每度电优惠(元)',
    diversionDetail_00108: '使用服务费折扣方式',
    diversionDetail_00109: '使用每度电优惠方式',
    diversionDetail_00110: '状态',
    diversionDetail_00111: '未开始',
    diversionDetail_00112: '已开始',
    diversionDetail_00113: '已生效',
    diversionDetail_00114: '未生效',
    diversionDetail_00115: '站点折扣历史',
    diversionDetail_00116: '请保存上一步操作！！',
    diversionDetail_00117: '开始时间不可为空',
    diversionDetail_00118: '结束时间不可为空',
    diversionDetail_00119: '优惠方式不可为空',
    diversionDetail_00120: '请输入小于100的正整数',
    diversionDetail_00121: '保存成功',
    diversionDetail_00122: '服务费折扣:{dis}%',
    diversionDetail_00123: '每度电优惠:{dis}元',
    diversionDetail_00124: '关闭开放给{name}站点信息，{name}将不会显示任何站点信息。',
    diversionDetail_00125: '确认关闭开放给{name}站点信息？',
    diversionDetail_10126: '推送设备状态',
    diversionDetail_10127: '推送设备状态失败，请重试！',
    diversionDetail_10128: '推送设备状态成功！',
    // *******
    // 设备管理--设备列表
    diversionDetail_00126: '设备序列号或名称',
    diversionDetail_00127: '设备类型',
    diversionDetail_00128: '设备状态',
    diversionDetail_00129: '空闲',
    diversionDetail_00130: '只看已插枪',
    diversionDetail_00131: '使用中',
    diversionDetail_00132: '仅看待拔枪',
    diversionDetail_00133: '充电桩自助激活',
    diversionDetail_00134: '添加设备',
    diversionDetail_00135: '设备序列号：',
    diversionDetail_00136: '厂商-型号：',
    diversionDetail_00137: '设备详情',
    diversionDetail_00138: '充电口：',
    diversionDetail_00139: '待挪车',
    diversionDetail_00140: '预约中',
    diversionDetail_00141: '已插枪',
    diversionDetail_00142: '待拔枪',
    diversionDetail_00143: '查看订单',
    diversionDetail_00144: '自助激活充电桩',
    diversionDetail_00145: '按照如下步骤自助激活充电桩后，可以将充电桩添加到充电站。',
    diversionDetail_00146: '第1步',
    diversionDetail_00147: '使用手机微信扫描左侧二维码',
    diversionDetail_00148: '温馨提示：可将页面保存到微信的收藏中，方便随时打开使用。',
    diversionDetail_00149: '第2步',
    diversionDetail_00150: '在手机端录入充电桩的SN，发起激活并在充电桩上确认。',
    diversionDetail_00151: '输入完整设备序列号',
    diversionDetail_00152: '通过设备编号查找桩',
    diversionDetail_00153: '此设备已被添加,继续添加会被转移到当前充电站,需要重启后才可以充电',
    diversionDetail_00154: '(例如：交流1号)',
    diversionDetail_00155: '设置设备在场站里的名称',
    diversionDetail_00156: '设备添加成功',
    diversionDetail_00157: '可以在 场站详情-设备管理-设备详情中 进行如下操作：',
    diversionDetail_00158: '1、修改设备名称',
    diversionDetail_00159: '2、变更启用状态',
    diversionDetail_00160: '3、设置电磁锁和雷达是否启用',
    diversionDetail_00161: '该设备已添加到当前站点',
    diversionDetail_00162: '请输入完整设备序列号',
    diversionDetail_00163: '请完善设备信息',
    diversionDetail_00164: '正常',
    diversionDetail_00165: '锁车',
    diversionDetail_00166: '休眠',
    diversionDetail_00167: '未知',
    diversionDetail_00168: '插枪',
    diversionDetail_00169: '充电结束',
    diversionDetail_00170: '仅看未归位',
    diversionDetail_00171: '已归位',
    diversionDetail_00172: '未归位',
    // POS机支付设置
    diversionDetail_00173: 'POS机支付设置',
    diversionDetail_00174: '推送POS机支付设置',
    diversionDetail_00175: '只支持带有POS机的充电桩进行该项配置',
    diversionDetail_00176: '预扣款金额',
    diversionDetail_00177: '场站当前资费货币类型',
    diversionDetail_00178: '确认推送',
    diversionDetail_00179: '场站当前资费货币类型',
    diversionDetail_00180: '本场站安装有POS机的桩列表',
    diversionDetail_00181: 'POS机支付状态',
    diversionDetail_00182: '全选',
    diversionDetail_00183: '设备序列号',
    diversionDetail_00184: '厂商-型号',
    diversionDetail_00185: 'POS机货币类型',
    diversionDetail_00186: '请选择设备',
    diversionDetail_00187: '推送成功',
    diversionDetail_00188: '等待响应',
    diversionDetail_00189: '桩端POS机货币类型与平台设置不符，请检查',
    diversionDetail_00190: '站点桩端POS机的货币类型是{list}，有冲突，建议线下检修不正确的桩。与站点选择的资费模板的货币类型{default}不同的部分桩无法开启POS机支付。请检查桩的货币类型，或者修改站点资费的货币类型。',
    diversionDetail_00191: '使用货币',
    diversionDetail_00192: '该站点需要选择{type}的资费类型，当前没有该资费模板，请新建资费。',
    diversionDetail_00193: '和',
    diversionDetail_00194: '请输入正确的预扣款金额',
    diversionDetail_00195: '提示信息',
    diversionDetail_00196: '修改资费',
    diversionDetail_00197: '当前场站结算货币类型和公共资费货币类型不同',
    diversionDetail_00198: '请重新选择货币类型为{type}的资费',
    diversionDetail_00199: '场站结算货币类型',
    diversionDetail_00200: '当前场站货币类型暂不支持添加团体资费及优惠。',
    diversionDetail_00201: '修改场站货币类型后，请修改公共资费和团体资费为同货币类型的资费，并重新推送资费',

    /* 公交充电 */
    detailDepot_00411: "输入0表示不限制",
    detailDepot_00412: "基本信息设置",
    detailDepot_00413: "整站限制充电总功率(KW)",
    detailDepot_00414: "基本信息设置",
    detailDepot_00415: "0表示不限制充电总功率",
    detailDepot_00416: "是否启用有序充电",
    detailDepot_00417: "请输入正确的数值",
    detailDepot_00418: "公交车辆",
    detailDepot_00419: "线路班次表",
    detailDepot_00420: "分组限制功率",
    detailDepot_00421: "车辆编号",
    detailDepot_00422: "车辆MAC",
    detailDepot_00423: "车辆VIN",
    detailDepot_00424: "整车动力蓄电池标称总能量（kW·h）",
    detailDepot_00425: "录入车辆",
    detailDepot_00426: "导入车辆",
    detailDepot_00427: "下载导入模板",
    detailDepot_00428: "生成",
    detailDepot_00429: "不能为空",
    detailDepot_00430: "整车动力蓄电池标称总能量只允许两位小数",
    detailDepot_00431: "车辆MAC参数非法",
    detailDepot_00432: "下载表格",
    detailDepot_00433: "您确定删除此车辆（车辆编号：{num}）?",
    detailDepot_00434: "选填",
    detailDepot_00435: "组内限制充电总功率(KW)",
    detailDepot_00436: "请将所有必填内容填写！",
    detailDepot_00437: "请输入正确的数值",
    detailDepot_00438: "修改功率分配组",
    detailDepot_00439: "查看实时运行",
    detailDepot_00440: "导入",
    detailDepot_00441: "车辆-充电车位推荐",
    detailDepot_00442: "下载",
    detailDepot_00443: "生效时间",
    detailDepot_00444: "当前使用",
    detailDepot_00445: "最近计划使用",
    detailDepot_00446: "线路",
    detailDepot_00447: "班次",
    detailDepot_00448: "出发时间",
    detailDepot_00449: "回站时间",
    detailDepot_00450: "车辆编码",
    detailDepot_00451: "出发时最低要求SOC (%)",
    detailDepot_00452: "请选择日期",
    detailDepot_00453: "立即生效",
    detailDepot_00454: "调整班次车辆",
    detailDepot_00455: "您确定删除车辆线路班次表？",
    detailDepot_00456: "点击上传",
    detailDepot_00457: "导入的文件",
    detailDepot_00458: "导入车辆线路班次表",
    detailDepot_00459: "（预留充电功率）",
    detailDepot_00460: "车辆MAC2（如车辆有第二个MAC，请在此输入）",
    detailDepot_00461: "车辆MAC2",
    detailDepot_00462: "MAC地址不能重复",
    detailDepot_00463: "车辆空调自动启动",
    detailDepot_00464: "车辆空调自动启动配置",
    detailDepot_00465: "空调自动启动距离发车时间（min）",
    detailDepot_00466: "是否启动车辆空调自动启动",
    detailDepot_00467: "公交车辆、线路班次表、空调自启动距离发车时间、是否启动车辆空调自启动 修改配置后，需重新推送配置。",
    detailDepot_00468: "推送车辆空调自动启动配置",
    detailDepot_00469: "确定推送车辆空调自动启动配置吗？",
    detailDepot_00470: "启动空调",
    detailDepot_00471: "命令已发送",
    detailDepot_00472: "公交车辆或线路班次表不能为空！",

    // 场站信息
    detailDepot_10001: "场站地址",
    detailDepot_10002: "地图位置",
    detailDepot_10003: "点击右侧按钮获取位置",
    detailDepot_10004: "修改位置",
    detailDepot_10005: "省市辖区编码",
    detailDepot_10006: "国家代码",
    detailDepot_10007: "支持车型",
    detailDepot_10008: "站点类型",
    detailDepot_10009: "客服电话",
    detailDepot_10010: "场站联系人",
    detailDepot_10011: "手机号",
    detailDepot_10012: "邮箱",
    detailDepot_10013: "建筑场所",
    detailDepot_10014: "车位地点",
    detailDepot_10015: "车位数量",
    detailDepot_10016: "停车费",
    detailDepot_10017: "实景图片",
    detailDepot_10018: "点击上传图片",
    detailDepot_10019: "仅支持PNG/JPG/GIG格式图片",
    detailDepot_10020: "不超过1M",
    detailDepot_10021: "获取位置失败",
    detailDepot_10022: "关闭",
    detailDepot_10023: "查找",
    detailDepot_10024: "搜索结果",
    detailDepot_10025: "将文件拖到此处，或 ",
    detailDepot_10026: "点击上传",
    detailDepot_10027: "请输入市辖区编码",
    detailDepot_10028: "市辖区编码格式错误(6位字符)",
    detailDepot_11026: "站点ID",
    detailDepot_21026: "查看位置",
    // 设备管理
    detailDepot_10029: "设备列表",
    detailDepot_10030: "设备配置",
    detailDepot_10031: "屏幕配置",
    detailDepot_10032: "白名单配置",
    detailDepot_10033: "每次修改后都推送配置信息，确保充电桩正常运行。",
    detailDepot_10034: "推送配置",
    detailDepot_10035: "充电桩本地信息设置",
    detailDepot_10036: "保存",
    detailDepot_10037: "修改",
    detailDepot_10038: "充电桩功能配置信息",
    detailDepot_10039: "补偿系数设置",
    detailDepot_10040: "延时费设置",
    detailDepot_10041: "限制",
    detailDepot_10042: "不限制",
    detailDepot_10043: "成功",
    detailDepot_10044: "失败",
    detailDepot_10045: "推送中",
    detailDepot_10046: "屏幕配置包括充电桩屏幕上的图片、文案和广告，根据设备和屏幕尺寸的不同，分别设置。",
    detailDepot_10047: "白名单设置",
    detailDepot_10048: "若设备离线，只支持白名单内的用户启动充电。",
    detailDepot_10049: "推送白名单",
    detailDepot_10050: "清除白名单",
    detailDepot_10051: "查看白名单",
    // 资费设置
    detailDepot_10052: "公共资费",
    detailDepot_10053: "团体资费",
    detailDepot_10054: "请将设置完的资费标准推送到充电桩上，充电桩才能以新的资费标准为用户进行充电费用的结算。注意：非智充桩可能会不支持该操作导致推送失败",
    detailDepot_10055: "推送资费",
    detailDepot_10056: "请先修改默认资费",
    detailDepot_10057: "该站点下没有设备，无法推送",
    detailDepot_10058: "推送失败",
    detailDepot_10059: "已取消删除",
    detailDepot_10060: "此操作将从列表中删除该团体，是否继续？",
    detailDepot_10061: "提示",
    detailDepot_10062: "团体列表",
    detailDepot_10063: "添加团体",
    detailDepot_10064: "新建资费",
    detailDepot_10065: "选择团体",
    detailDepot_10066: "请输入团体名称",
    detailDepot_10067: "用户数量",
    detailDepot_10068: "资费标准",
    detailDepot_10069: "请选择资费",
    detailDepot_10070: "折扣选择",
    detailDepot_10071: "单次充电费用不打折",
    detailDepot_10072: "单次充电服务费打折(%) ",
    detailDepot_10073: "举例说明：如果设置单次充电服务费8.5折，此次充电费用100元(含电费70元、服务费30元),则此次需实际支付金额95.5元(70+30*85%=95.5)",
    detailDepot_10074: "85表示8.5折，0表示全免",
    detailDepot_10075: "单次充电总费用打折(%) ",
    detailDepot_10076: "举例说明：如果设置单次充电总费用8.5折，此次充电总费用100元，则此次需要实际支付金额85元(100*85%=85)",
    detailDepot_10077: "团体添加成功",
    detailDepot_10078: "在资费标准中点击[ 推送收费 ] 按钮资费才能生效！",
    detailDepot_10079: "可以在[ 充电站管理  站点详情  站点信息 ] 中进行如下操作 ：",
    detailDepot_10080: "1、移除已添加的团体",
    detailDepot_10081: "2、添加新的团体",
    detailDepot_10082: "上一步",
    detailDepot_10083: "下一步",
    detailDepot_10084: "请选择团体",
    detailDepot_10085: "请选择资费标准",
    detailDepot_10086: "用户组不可重复被添加",
    detailDepot_10087: "请设置折扣参数",
    detailDepot_10088: "请输入小于100的正整数",
    detailDepot_10089: "选择资费及折扣",
    detailDepot_10090: "单次成功点总费用不打折(%)",
    detailDepot_10092: "免收占用费时段",
    detailDepot_10093: "无免收占用费时段",
    detailDepot_10094: "有免收占用费时段",
    detailDepot_10095: "时段为场站所在当地时段，最多可以添加5个时段",
    detailDepot_10096: "可以根据场站运营商需要，在此设置免收占用费时段。",
    detailDepot_10097: "时间段有重叠！",
    detailDepot_10098: "请填写占用费时段",
    detailDepot_10099: "结束时间需大于开始时间",
    detailDepot_10100: "添加时段",
    detailDepot_10101: "离线/故障",
    detailDepot_10102: "根据30天的平均值当前余额能用{num}天",
    detailDepot_10103: "自定义时间与前面选项重复，请直接选择该选项或者重新填写自定义时间",
    //储能
    detailDepot_10104: "储充工作模式选择",
    detailDepot_10105: "Auto自动模式",
    detailDepot_10106: "Eco经济模式",
    detailDepot_10107: "Max功率模式",
    detailDepot_10108: "利用储能装置弥补充电功率缺口，快速充满车辆。",
    detailDepot_10109: "低电价时储能、高电价时服务，降低用电成本。",
    detailDepot_10110: "系统自动平衡功率和成本两方面的需求。",
    detailDepot_10111: "了解更多",
    detailDepot_10112: "储充空调运行模式",
    detailDepot_10123: "经济模式",
    detailDepot_10124: "高效模式",
    detailDepot_10125: "电网电费资费设置",
    detailDepot_10126: "新建电网资费",
    detailDepot_10127: "充电资费",

    detailDepot_10128: "电网资费",
    detailDepot_10129: "尖峰平谷对应价格",
    detailDepot_10130: '电网电费({type}/{unit})',
    detailDepot_10131: '上一步',
    detailDepot_10132: '下一步',
    detailDepot_10133: '请选择资费的尖峰平谷时间段',
    detailDepot_10134: '尖',
    detailDepot_10135: '峰',
    detailDepot_10136: '平',
    detailDepot_10137: '谷',
    detailDepot_10138: '尖峰谷平时段',
    detailDepot_10139: '1、检查场站中所有启用设备的重要配置与平台是否一致，如果不一致您可以选择前往相关页面进行配置推送。',
    detailDepot_10140: '2、此检查要求充电桩的Android应用的固件版本号在4.0.0以上。',
    detailDepot_10141: '3、第三方设备不支持此检查。',
    detailDepot_10142: "开始检查",
    detailDepot_10143: "自检中，请稍候",
    detailDepot_10144: "重新检查",
    detailDepot_10145: "检查时间",
    detailDepot_10146: "状态检查",
    detailDepot_10147: "硬件寿命",
    detailDepot_10148: "自检项",
    detailDepot_10149: "确认并恢复急停",
    detailDepot_10150: "设备使用中，自检取消，请空闲后操作",
    detailDepot_10151: "当前设备离线",
    detailDepot_10152: "设备自检超时，请重试",
    detailDepot_10153: "差",
    detailDepot_10154: "中",
    detailDepot_10155: "良",
    detailDepot_10156: "此检查要求充电桩的Android应用的固件版本号在4.0.0及以上",
    detailDepot_10157: "未查询到上次自检结果",
    detailDepot_10158: "立即恢复",
    detailDepot_10159: "先检查设备，再考虑恢复",
    detailDepot_10160: "免责申明",
    detailDepot_10161: "为了确保充电桩的安全运行，我们建议您在恢复急停按钮前进行硬件检查，如发现任何硬件故障或安全隐患，请停止使用充电桩，并联系我们的技术支持团队。我们将尽快提供解决方案，以确保您的充电桩能够安全、稳定地运行。",
    detailDepot_10162: "请注意，此免责申明旨在提醒您，在不确定充电桩状态地前提下，选择直接恢复急停按钮，会存在一定风险，我们将不承担任何由此产生的责任。",
    detailDepot_10163: "确定直接恢复",
    detailDepot_10164: "以下设备配置不一致",
    detailDepot_10165: "以下设备配置一致",
    detailDepot_10166: "场站中未找到支持检查的设备",
    detailDepot_10167: "响应超时，请重试",
    detailDepot_10168: "配置检查",
    // add
    accountManage_00002: "账号",
    accountManage_00003: "拥有人",
    accountManage_00004: "手机号",
    accountManage_00005: "角色",
    accountManage_00006: "添加账号",
    accountManage_00007: "操作",
    accountManage_00008: "管理场站范围",
    accountManage_00009: "添加账户",
    accountManage_000010: "账户列表",
    accountManage_000011: "编辑账号",
    accountManage_000012: "登录运营平台的账号",
    accountManage_000013: "密码",
    accountManage_000014: "登录运营平台的密码",
    accountManage_000015: "支持字母和数字，不超过20个字",
    accountManage_000016: "拥有人",
    accountManage_000017: "使用真实姓名方便后续查找",
    accountManage_000018: "手机号",
    accountManage_000019: "方便他人联系管理员",
    accountManage_000020: "角色",
    accountManage_000021: "运营商管理员",
    accountManage_000022: "请选择",
    accountManage_000023: "请选择账号角色",
    accountManage_000024: "操作成功",
    accountManage_000025: "请输入充电站名称",
    accountManage_000026: "添加充电站成功",
    accountManage_000027: "已选充电站",
    accountManage_000028: "删除成功",
    accountManage_000029: "超级管理员",
    accountManage_000030: "您确定要删除吗?",
    accountManage_000031: "已取消删除",
    accountManage_000032: "添加时间",
    accountManage_000033: "邮箱",
    detailDepot_11051: '平台支持的最大充电口数已达上限，请前往"运营平台-开通服务"页面，扩容支持充电口数量',
    detailDepot_11052: "能源站点",
    detailDepot_11152: "添加失败！组内所有型号的充电口离线最大充电功率*组内充电口数之和，需小于等于组内限制充电总功率设置中的最小值",
    detailDepot_11053: "储能柜",
    // #7626 小桔优化
    detailDepot_20001: "经营趋势",
    detailDepot_20002: "收入分析",
    detailDepot_20003: "充电量分析",
    detailDepot_20004: "效率分析",
    detailDepot_20005: "用户分析",
    detailDepot_20006: "分析",
    detailDepot_20007: "总收入趋势",
    detailDepot_20008: "服务费（元）",
    detailDepot_20009: "电费（元）",
    detailDepot_20010: "收入（元）",
    detailDepot_20011: "用户来源收入占比",
    detailDepot_20012: "第三方流量中各流量占比",
    detailDepot_20013: "用户来源收入趋势 ",
    detailDepot_20014: "第三方流量收入趋势",
    detailDepot_20015: "按电量利用率趋势",
    detailDepot_20016: "按时长利用率趋势",
    detailDepot_20017: "利用率 = 日充电量 / 日满负荷充电量<br/>日满负荷充电量 = 充电桩额定功率 * 24小时",
    detailDepot_20018: "利用率 = 充电枪口日充电时长 / 24小时",
    detailDepot_20019: "交流设备充电利用率（%）",
    detailDepot_20020: "直流设备充电利用率（%）",
    detailDepot_20021: "充电量 (度)",
    detailDepot_20022: "充电量趋势",
    detailDepot_20023: "用户来源充电量占比",
    detailDepot_20024: "用户来源充电量趋势",
    detailDepot_20025: "第三方流量充电量趋势",
    detailDepot_20026: "用户数趋势",
    detailDepot_20027: "用户来源充电订单数占比",
    detailDepot_20028: "充电用户数",
    detailDepot_20029: "第一次充电用户数",
    detailDepot_20030: "用户来源充电订单数趋势",
    detailDepot_20031: "第三方流量充电订单数趋势",
    detailDepot_20032: "枪均收入趋势",
    detailDepot_20033: "延时费（{0}）",
    detailDepot_20034: "0-24小时平均充电量趋势",
    detailDepot_20035: "枪均充电量",
    detailDepot_20036: "枪均度数",
    detailDepot_20037: "枪均充电量",
    detailDepot_20038: "枪均单量",
    detailDepot_20039: "枪均订单数",
    detailDepot_20040: "单",
    detailDepot_20041: "充电订单数",
    detailDepot_20042: "枪均服务费（{0}）",
    detailDepot_20043: "枪均电费（{0}）",
    detailDepot_20044: "枪均延时费（{0}）",
    detailDepot_20045: "利用率 = 日充电量 / 日满负荷充电量<br/>日满负荷充电量 = 充电桩额定功率 * 24小时",
    detailDepot_20046: "利用率 = 充电枪口日充电时长 / 24小时",
    detailDepot_20047: "总收入({0})",
    detailDepot_20048: "总服务费({0})",
    detailDepot_20049: "总电费({0})",
    detailDepot_20050: "总延时费({0})",
    detailDepot_20051: "第三方导流来源总收入({0})",
    detailDepot_20052: "总充电量(度)",
    detailDepot_20053: "总自营&加盟商来源充电量(度)",
    detailDepot_20054: "总第三方导流来源充电量(度)",
    detailDepot_20055: "总充电用户数(人)",
    detailDepot_20056: "总充电订单数(单)",
    detailDepot_20057: "总首次充电订单数(单)",
    detailDepot_20058: "总自营&加盟商充电订单数(单)",
    detailDepot_20059: "总第三方导流充电订单量(单)",
    // 能源微网设置
    detailDepot_30000: "每次修改后都推送配置信息，确保微网硬件系统正常运行。此处设置的参数会批量推送给场站可以运行该参数的微网设备。",
    detailDepot_30001: "储能设置",
    detailDepot_30002: "储能最低停止放电SOC",
    detailDepot_30003: "防止电网断电或储能电池过放",
    detailDepot_30004: "储能最高停止补电SOC",
    detailDepot_30005: "防止储能电池容量衰减导致过充",
    detailDepot_30006: "如果场站设备里有NZS储充设备，设置该选项为“是”，可以在能源中心中查看NZS为本场站带来的运行及收益变化",
    detailDepot_30007: "当选择是时，表示您允许储能电池使用光伏补能充电。<br/>当选择否时，储能电池将仅通过电网电力补能充电。",
    detailDepot_30008: "高级设置",
    detailDepot_30009: "允许使用光伏电力储能",
    detailDepot_30010: "推送设置",
    detailDepot_30011: "请完整填写表单",
    detailDepot_30012: "双平台",
    detailDepot_40000: "是否支持VIN启动充电",
    detailDepot_40001: "如场站设备支持以VIN为身份来即插即充，在此设置后，可支持场站以VIN为标识进行车辆启动充电时的身份验证，验证通过后即可启动充电。具体使用场景举例：使用即插即充卡方式支持车辆在场站即插即充，需在此设置为“是”。",
    detailDepot_40002: "设备离线时充电卡充电配置",
    detailDepot_40003: "允许所有",
    detailDepot_40004: "允许白名单内的",
    detailDepot_40005: "离线时，充电卡启动充电模式",
    detailDepot_40006: "批量添加设备",
    detailDepot_40007: "请输入完整的设备SN。支持多个设备SN输入，最多可输入50个。",
    detailDepot_40008: "一行一个设备序列号，请勿输入非SN外的符号字母空格等",
    detailDepot_40009: "请输入设备名称",
    detailDepot_40010: "校验失败",
    detailDepot_40011: "校验成功",
    detailDepot_40012: "请检查设备SN是否正确",
    detailDepot_40013: "最多可输入50个",
    detailDepot_40014: "设备SN已被其他场站添加",
    detailDepot_40015: "枪口数超限制",
    detailDepot_40016: "请输入设备名称",
    detailDepot_40017: "{0}个",
    detailDepot_40018: "总共输入设备SN {0}个，校验成功{1}个，失败{2}个",
    detailDepot_40019: "添加设备",
    detailDepot_40020: "{0}个设备添加成功",
    // #10238
    detailDepot_40021: "添加非智充桩",
    detailDepot_40022: "请输入非智充桩设备型号",
    detailDepot_40023: "申请非智充桩型号",
    detailDepot_40024: "云快充协议中的设备ID号",
    detailDepot_40025: "请输入设备序列号",
    detailDepot_40026: "使用自动生成的序列号",
    detailDepot_40027: "接入说明：",
    detailDepot_40028: "支持云快充协议版本：",
    detailDepot_40029: "智充云连接地址：",
    detailDepot_40030: "设备序列号（云快充协议中的设备ID号）为：",
    detailDepot_40031: "没有当前所需的非智充桩型号",
    detailDepot_40032: "枪口数",
    detailDepot_40033: "整桩额定功率",
    detailDepot_40034: "枪口最小功率",
    detailDepot_40035: "输出电压范围",
    detailDepot_40036: "充电桩型号",
    detailDepot_40037: "请填写电桩品牌名",
    detailDepot_40038: "请填写电桩系列编码",
    detailDepot_40039: "请填写电桩型号编码",
    detailDepot_40040: "充电桩类型",
    detailDepot_40041: "直流桩",
    detailDepot_40042: "交流桩",
    detailDepot_40043: "充电桩枪口数",
    detailDepot_40044: "请输入充电桩枪口数",
    detailDepot_40045: "整桩额定功率（kW）",
    detailDepot_40046: "请输入整桩额定功率",
    detailDepot_40047: "枪口最小功率（kW）",
    detailDepot_40048: "请输入最小功率",
    detailDepot_40049: "输出电压范围（V）",
    detailDepot_40050: "请输入最小输出电压",
    detailDepot_40051: "至",
    detailDepot_40052: "请输入最大输出电压",
    detailDepot_40053: "枪口最大电流（A）",
    detailDepot_40054: "请输入最大电流",
    detailDepot_40055: "申请成功",
    detailDepot_40056: "申请结果会在平台右上角通知栏进行通知。",
    detailDepot_40057: "确定申请",
    detailDepot_40058: "请填写完整信息",
    detailDepot_40059: "请正确填写内容",
    detailDepot_40060: "该型号已存在或正在审核中，请勿重复申请",
    detailDepot_51000: "请设置百分比",
    detailDepot_51001: "充电订单的实际支付金额不会超过此处设置的预扣款金额，包括含有增值税、延时费的充电订单。",

    //设备列表自检
    eqDetails_30003: "硬件自检",
    eqDetails_30004: "1、检查设备硬件状态，过程需要几十秒",
    eqDetails_30005: "2、此检查要求充电桩的Android应用的固件版本号在4.0.0以上。",
    eqDetails_30006: "3、第三方设备不支持此检查。",
    eqDetails_30007: "上次检查结果",
    eqDetails_30008: "开始检查",
    eqDetails_30009: "自检中，请稍候",
    eqDetails_30010: "重新检查",
    eqDetails_30011: "检查时间",
    eqDetails_30012: "状态检查",
    eqDetails_30013: "硬件寿命",
    eqDetails_30014: "自检项",
    eqDetails_30015: "确认并恢复急停",
    eqDetails_30016: "设备使用中，自检取消，请空闲后操作",
    eqDetails_30017: "当前设备离线",
    eqDetails_30018: "设备自检超时，请重试",
    eqDetails_30020: "差",
    eqDetails_30021: "中",
    eqDetails_30022: "良",
    eqDetails_30023: "此检查要求充电桩的Android应用的固件版本号在4.0.0及以上",
    eqDetails_30024: "未查询到上次自检结果",
    eqDetails_30025: "立即恢复",
    eqDetails_30026: "先检查设备，再考虑恢复",
    eqDetails_30027: "免责申明",
    eqDetails_30028: "为了确保充电桩的安全运行，我们建议您在恢复急停按钮前进行硬件检查，如发现任何硬件故障或安全隐患，请停止使用充电桩，并联系我们的技术支持团队。我们将尽快提供解决方案，以确保您的充电桩能够安全、稳定地运行。",
    eqDetails_30029: "请注意，此免责申明旨在提醒您，在不确定充电桩状态地前提下，选择直接恢复急停按钮，会存在一定风险，我们将不承担任何由此产生的责任。",
    eqDetails_30030: "确定直接恢复",
    eqDetails_30031: "自检项",
    eqDetails_30032: "状态",
    eqDetails_30033: "自检取消，请检修后操作",
    eqDetails_30034: "此信息在充电桩出现故障时会显示在桩端屏幕上",
    eqDetails_30035: "场站联系人电话",
    eqDetails_30036: "可在“场站信息”场站联系人处修改此信息",
    eqDetails_30037: "生成非智充设备序列号",
    eqDetails_30038: "生成并导出非智充设备序列号",
    eqDetails_30039: "支持最多一次生成50个SN",
    eqDetails_30040: "请等待，生成中...",
    eqDetails_30041: "恭喜，已完成！",
    eqDetails_30042: "已生成SN列表：",
    eqDetails_30043: "生成并导出",
    eqDetails_30044: "导出",
    eqDetails_30045: "填写正确的设备序列号",
    eqDetails_30046: "正确填写数量",
    eqDetails_30047: "",
}