<template>
  <div class="container userGroupMenu_container">
    <div class="heade-bar">
      <span class="collapse-switcher" @click.prevent="collapse">
        <i class="iconfont icon-shouqi1" v-show="isCollapse || isShrink"></i>
        <i class="iconfont icon-zhankai1" v-show="!isCollapse || !isShrink"></i>
      </span>
      <div class="logo">
        <img :src="adminImg" v-if="adminImg">
        <span>{{ sysName }} {{$t('common_00320')}}</span>
      </div>
      <!-- <span class="full-screen">
        <i class="iconfont icon-kanban"></i>
        <el-tooltip class="item" effect="dark" :content="$t('common_00233')" placement="bottom">
          <el-badge :value="notifyCount" :max="99" class="item">
            <i class="iconfont icon-xiaoxi1" @click="toNotify"></i>
          </el-badge>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('common_00321')" placement="bottom">
          <i class="iconfont icon-fangda" v-show="!isFullScreen" @click.prevent="screenfull"></i>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('common_00322')" placement="bottom">
          <i class="iconfont icon-suoxiao" v-show="isFullScreen" @click.prevent="screenfull"></i>
        </el-tooltip>
      </span> -->
      <span class="tool-bar">

        <el-dropdown class="user-info-dropdown" trigger="hover">
          <span class="el-dropdown-link">
            <img :src="headImageUrl" v-if="headImageUrl">
            {{ userTitle }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="FLAG_CHANGE_PW = true">{{
              $t("common_00246")
            }}</el-dropdown-item>
            <el-dropdown-item @click.native="logout">{{
              $t("common_00001")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </div>
    <div class="myMain">
      <div class="menu">
        <div class="menu-bar" :class=" isCollapse || isShrink ? 'menu-bar-collapse-width' : 'menu-bar-width'">
          <div class="menu_wrap" :class=" isCollapse || isShrink ? 'menu-bar-collapse-width' : 'menu-bar-width'">
            <div class="title_box" v-for="(i, key) in list" :key="key" :index="String(key)" :class="{ 'is-opened': i.active == isactive ? true : false }">
              <a style="display: inline-block;height: 100%;width: 100%;" :href="i.router" :target="((i.router == 'analyzeBI.html')||(i.router == 'chargeMonitor.html')||(i.router == 'http://www.xcharge.com/OpenAPI/')) 
                    && '_blank'" class="menu_li">
                <i :class="i.icon"></i>
                <span class="titleName">{{i.name}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="main-con">
        <slot></slot>
      </div>
    </div>
    <el-dialog :title="$t('common_00246')" :lock-scroll="false" :visible="FLAG_CHANGE_PW" @close="close" width="30%">
      <div class="dialog-changePw">
        <div class="model">
          <div class="title">{{ $t("common_00247") }}</div>
          <el-input size="medium" v-model="password" :placeholder="$t('common_00250')" type="password" />
        </div>
        <div class="model">
          <div class="title">{{ $t("common_00248") }}</div>
          <el-input size="medium" v-model="newPassword" :placeholder="$t('common_00249')" type="password" />
        </div>
        <div class="model">
          <div class="title">{{ $t("common_00268") }}</div>
          <el-input size="medium" v-model="newPassword1" :placeholder="$t('common_00249')" type="password" />
        </div>
        <div class="buttons">
          <el-button @click="close" size="medium">{{
            $t("common_00215")
          }}</el-button>
          <el-button type="primary" @click="uptPassword" size="medium">{{
            $t("common_00234")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  requestUrl,
  ajax,
  checkLogin,
  getLoginInfo,
  checkIdType,
} from "@/assets/utils/network";
import screenfull from "screenfull";
export default {
  data() {
    return {
      aa: false,
      loginId: "",
      password: "",
      newPassword: "",
      newPassword1: "",
      FLAG_CHANGE_PW: false,
      notifyCount: 0, // 通知
      list: [],
      // 团体管理员
      userGroup: [
        {
          name: this.$parent.$t("common_00360"),
          icon: "iconfont icon-xinxi",
          iconS: "iconfont icon-xitong",
          active: 1,
          router: "groupBaseInfo.html",
          permission: "siteInfo",
        },
        {
          name: this.$parent.$t("common_00361"),
          icon: "iconfont icon-a-changzhanbeifen3",
          iconS: "iconfont icon-xitong",
          router: "preferentialSite.html",
          permission: "deviceMonitorList",
          active: 2,
        },
        {
          name: this.$parent.$t("common_00362"),
          icon: "iconfont icon-a-bianzu15beifen5",
          iconS: "iconfont icon-xitong",
          router: "manageUsers.html",
          permission: "deviceMonitorList",
          active: 3,
        },
        {
          name: this.$parent.$t("common_00363"),
          icon: "iconfont icon-a-dingdanbeifen4",
          iconS: "iconfont icon-xitong",
          router: "userOrderQuery.html",
          permission: "chargeBillInfo",
          active: 4,
        },
        {
          name: this.$parent.$t("common_00364"),
          icon: "iconfont icon-a-bianzu17beifen3",
          iconS: "iconfont icon-xitong",
          router: "chargingAmount.html",
          permission: "userGroupChargeBillStats",
          active: 5,
        },
      ],
      isCollapse: false,
      isFullScreen: false,
      sysName: "",
      userTitle: "",
      headImageUrl: "",
      adminImg: "",
      screenWidth: document.body.clientWidth,
      timer: false,
    };
  },
  props: ["isactive"],
  mounted() {
    var notifyCountUrl = requestUrl + "/message/unread/count";
    this.getNotifyCount(notifyCountUrl);
    window.onresize = () => {
      this.screenWidth = document.body.clientWidth;
    };
  },
  watch: {
    screenWidth(newValue) {
      if (!this.timer) {
        this.screenWidth = newValue;
        this.timer = true;
        setTimeout(() => {
          this.timer = false;
        }, 400);
      }
    },
  },
  computed: {
    isShrink() {
      return this.screenWidth < 1370;
    },
  },
  methods: {
    checkIdType(type) {
      return checkIdType(type);
    },
    //修改密码
    check() {
      if (!this.password) {
        this.$message.error(this.$t("common_00251"));
        return false;
      }
      if (!this.newPassword) {
        this.$message.error(this.$t("common_00252"));
        return false;
      }
      if (!this.newPassword1) {
        this.$message.error(this.$t("common_00253"));
        return false;
      }
      if (this.newPassword != this.newPassword1) {
        this.$message.error(this.$t("common_00254"));
        return false;
      }
      return true;
    },
    close() {
      this.FLAG_CHANGE_PW = false;
    },
    //修改密码
    uptPassword() {
      if (!this.check()) return;
      var param = {};
      param.loginId = this.loginId;
      param.password = this.password;
      param.newPassword = this.newPassword;
      var url = requestUrl + "/password";
      ajax({
        type: "POST",
        url: url,
        dataType: "json",
        async: false,
        data: JSON.stringify(param),
        success: (response) => {
          var info = JSON.parse(response);
          if (info.error) {
            this.$message.error(
              info.error.msg ? info.error.msg : info.error.code
            );
          } else {
            if (info.result) {
              this.$message({
                message: this.$t("common_00201"),
                type: "success",
              });
              this.FLAG_CHANGE_PW = false;
            }
          }
        },
      });
    },
    collapse() {
      this.isCollapse = !this.isCollapse;
    },
    screenfull() {
      this.isFullScreen = !this.isFullScreen;
      screenfull.toggle();
    },
    //退出登录
    logout() {
      if(window.localStorage.getItem('userGroupMenu')=='userGroupMenu'){
        window.localStorage.removeItem('userGroupMenu');
      }
      if(window.sessionStorage.getItem('groupName')){
        window.sessionStorage.removeItem('groupName');
      }
      let object = new Object();
      object.loginId = this.loginId;
      ajax({
        type: "POST",
        url: `${requestUrl}/logout`,
        data: JSON.stringify(object),
        dataType: "json",
        success: (data) => {
          if (this.roleId == "1013636002184695808") {
            window.location.href = "http://www.elecbay.cn/xjyy";
          } else if (this.roleId == "1013637748365725696") {
            window.location.href = "http://www.elecbay.cn/service/";
          } else {
            window.location.href = "./login.html";
          }
        },
      });
    },
    // 跳转通知页面
    toNotify() {
      window.open("./notify.html");
    },
    // 未读通知条数
    getNotifyCount(url) {
      var om = this;
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: function (response) {
          var totalCount = JSON.parse(response).totalCount;
          om.notifyCount = totalCount;
        },
      });
    },
    // 获取用户组名称
    getList(url) {
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: function (response) {
          var info = JSON.parse(response).result[0];
          window.sessionStorage.setItem("groupName", info.title);
        },
      });
    },
  },
  created() {
    let info = getLoginInfo();
    var permissions = checkLogin("permissionsV2");
    var permissionsArr = [];
    for (var i in permissions) {
      permissionsArr.push(i);
    }
    if (permissionsArr) {
      // 团体管理员
      if (checkIdType("userGroup")) {
        this.list = this.userGroup;
      }else{
        if(window.localStorage.getItem('userGroupMenu')=='userGroupMenu'){
          window.localStorage.removeItem('userGroupMenu');
        }
        if(window.sessionStorage.getItem('groupName')){
          window.sessionStorage.removeItem('groupName');
        }
        window.location.href = "./preview.html";
        return
      }
    }
    let loginInfo = checkLogin("name");
    this.sysName = loginInfo.name;
    this.adminImg = checkLogin("imgUrl");
    // let info = getLoginInfo();
    this.userTitle = info.username ? info.username : info.loginId;
    this.headImageUrl = info.headImageUrl;
    var url = requestUrl + "/usergroup?id=" + checkLogin("userGroupId");
    this.getList(url);
  },
};
</script>

<style scoped lang="scss">
@import "../assets/font/iconfont.css";
.container {
  background: #f7f7fa;
  .heade-bar {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    display: flex;
    height: 60px;
    line-height: 60px;
    background: #fff;
    box-shadow: -10px 10px 20px 0px rgba(30, 30, 30, 0.05);
    border-bottom: 1px solid #ececec;
    .collapse-switcher {
      width: 40px;
      text-align: center;
      cursor: pointer;
      color: #9097ac;
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 40px;
        margin-left: 10px;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        &:hover {
          background: #eef2fd;
          transition: 0.4s;
        }
      }
    }
    .logo {
      flex: 1;
      height: 60px;
      line-height: 60px;
      margin-left: 10px;
      img {
        margin: 12px 10px 0;
        width: 36px;
        height: 36px;
        border-radius: 4px;
      }
      span {
        font-size: 18px;
        font-weight: 600;
        color: #212943;
        vertical-align: top;
        white-space: nowrap;
      }
    }
    .full-screen {
      display: flex;
      cursor: pointer;
      color: #8087a0;
      padding-right: 20px;
      align-items: center;
      .el-badge {
        height: 40px;
        line-height: 40px;
      }
      .iconfont {
        font-size: 40px;
        margin: 0 10px;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        &:hover {
          background: #eef2fd;
          transition: 0.4s;
        }
      }
    }
    .tool-bar {
      .theme-picker {
        padding-right: 10px;
      }
      .lang-selector {
        padding-right: 10px;
        font-size: 15px;
        color: #fff;
        cursor: pointer;
      }
      .user-info-dropdown {
        font-size: 14px;
        padding-right: 20px;
        color: #2b3041;
        cursor: pointer;
        img {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          margin: 12px;
          float: left;
          border-radius: 35px;
        }
      }
    }
  }
  .myMain {
    display: flex;
    margin-top: 60px;
    .menu_wrap {
      position: fixed;
      z-index: 3;
      background: #fff;
      padding-top: 20px;
      border-right: none;
      box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.1);
      min-height: calc(100vh - 61px);
      .title_box {
        position: relative;
        height: 50px !important;
        line-height: 50px !important;
        font-size: 14px;
        margin-bottom: 1px;
        &:hover {
          background-color: #eef2fc;
          a > i,
          a > .titleName {
            color: #5278e2;
          }
          &:after {
            position: absolute;
            content: "";
            width: 4px;
            height: 50px;
            background-color: #5278e2;
            left: 0;
            top: 0;
          }
        }
        a {
          white-space: nowrap;

          & > i {
            padding-right: 10px;
            color: #8087a0;
            font-size: 20px;
          }
          & > .titleName {
            color: #8087a0;
          }
        }
      }
    }
    .main-con {
      padding: 20px;
      flex: 1;
    }
  }
  .menu-bar-width {
    width: 200px;
    transition: width 0.4s ease-out;
    .title_box {
      padding-left: 40px !important;
    }
    .titleName {
      visibility: visible;
    }
  }
  .menu-bar-collapse-width {
    width: 65px;
    transition: width 0.3s;
    .title_box {
      padding-left: 20px !important;
    }
    .titleName {
      visibility: hidden;
    }
    .main-con {
      min-width: 900px;
    }
  }
  .titleName {
    color: #8087a0;
  }
}
.dialog-changePw {
  color: #41526d;
  .model {
    margin-bottom: 10px;
    .title {
      margin-bottom: 6px;
    }
  }
  .buttons {
    text-align: right;
  }
}
/deep/.el-badge__content {
  min-width: 12px;
  box-sizing: content-box;
}
</style>
<style>
a {
  background-color: transparent;
  text-decoration: none;
  color: #1c2237;
}
.query-btn {
  color: #5278e2 !important;
  border-color: #5278e2 !important;
  font-weight: 600 !important;
}
.userGroupMenu_container .heade-bar .el-badge__content.is-fixed {
  top: 10px !important;
  right: 30px !important;
}
.userGroupMenu_containe .heade-bar .el-badge__content {
  height: 16px !important;
  line-height: 16px !important;
  padding: 0 2px !important;
}
.el-dialog__header {
  background: #eff2f6;
  font-weight: 600;
  padding: 0 0 0 20px !important;
  line-height: 60px;
}
.el-dialog__header span {
  color: #8087a0;
}
/* 1 */

.userGroupMenu_containe .is-opened {
  background-color: #e7f0ff;
}
.userGroupMenu_containe .is-opened:after {
  position: absolute;
  content: "";
  width: 4px;
  height: 50px;
  background-color: #5278e2;
  left: 0;
  top: 0;
}
.userGroupMenu_containe .is-opened .titleName {
  color: #5278e2 !important;
}
.userGroupMenu_containe .is-opened .iconfont {
  color: #5278e2 !important;
  font-weight: 600;
}
</style>